<div class="sectionContainer" #sectionContainer>

  <div class="line-box section">
    <div class="componentsFlex">
       <ng-content select=".handler">

       </ng-content>
       

      <customInput cdkDragDisabled  [(ngModel)]="section.name"  [options]='extraClass.sectionNameCustomInputOptions' (change)="checkIfElementIsFull(section.name,'name')" ></customInput>
      <custom-button [options]="extraClass.ccaButtonOptions"  (click)="$event.stopImmediatePropagation();onclickCreateCCA()" />
    </div>
    <div class="componentsFlex">
      <custom-button [options]="extraClass.subsectionButtonOptions" (click)="onClickCreateSubsection()" />
      <icon-container [item]="section" [icons]="extraClass.iconsSection" />
    </div>
  </div>
  <span class="leftLineVertical" #leftLineVertical  ></span>

  <ng-content [subsectionsAndCcas]></ng-content>

</div>