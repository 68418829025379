import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'component-update-section',
  templateUrl: './component-update-section.component.html',
  styleUrls: ['./component-update-section.component.scss'],
})
export class ComponentUpdateSectionComponent implements OnInit {
  @Input({ required: true }) header!: string;

  constructor() {}

  ngOnInit() {}
}
