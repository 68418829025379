<div
  class="component-tree"
  cdkDropList
  (cdkDropListDropped)="drop($event, sections)"
>
  @for (section of sections; track section;let indexSection = $index) {
  <section-line
    [isComponent]="isComponent"
    #sectionLine
    [section]="section"
    (recall)="onRecall()"
    cdkDropList
    cdkDrag
    (cdkDropListDropped)="drop($event, section.children)"
  >
    <div class="custom-placeholder" *cdkDragPlaceholder></div>
    <!-- <img class="grab absolute" cdkDragHandle [src]="extraClass.IconsHandlerService.getDragableIcon()" alt=""> -->
    <img
      class="grab p-left handler"
      handler
      cdkDragHandle
      [src]="extraClass.IconsHandlerService.getDragableIcon()"
      alt=""
    />

    @for (child of section.children; track child; let indexChild = $index; let
    last = $last) { @if(child.type === 'subsection'){
    <subsection-line
      [isComponent]="isComponent"
      [isOpen]="child.isOpen"
      (moveTo)="openMoveToPopup($event)"
      #subsectionLine
      [subsection]="child"
      [last]="last"
      (recall)="onRecall()"
      cdkDropList
      (cdkDropListDropped)="drop($event, child.children)"
      cdkDrag
      (onOpen)="toggleOpen($event, indexChild)"
    >
      <div class="custom-placeholder" *cdkDragPlaceholder></div>
      <img
        class="grab p-left handler"
        handler
        cdkDragHandle
        [src]="extraClass.IconsHandlerService.getDragableIcon()"
        alt=""
      />

      @for (cca of child.children; track cca; let indexSubsection = $index) {

      <cca-line
        [isComponent]="isComponent"
        [isOpen]="cca.isOpen"
        (moveTo)="openMoveToPopup($event)"
        [cca]="returnIfCCA(cca)"
        [isInside]="true"
        [isInSubsection]="true"
        (recall)="onRecall()"
        cdkDrag
        (onOpenCCA)="toggleOpen($event, indexChild)"
      >
        <div class="custom-placeholder" *cdkDragPlaceholder></div>
        <img
          class="grab p-left handler"
          handler
          cdkDragHandle
          [src]="extraClass.IconsHandlerService.getDragableIcon()"
          alt=""
        />
      </cca-line>
      }
      <div class="emptyAddCCALine">
        <custom-button
          [options]="extraClass.emptyAddCCALineButtonOptions"
          (click)="onclickCreateCCA(child)"
        />
      </div>
    </subsection-line>
    } @if(child.type === 'cca' || child.type === 'ccaType'){
    <cca-line
      [isComponent]="isComponent"
      [isOpen]="child.isOpen"
      (moveTo)="openMoveToPopup($event)"
      [cca]="child"
      [isInside]="true"
      [last]="last"
      (recall)="onRecall()"
      cdkDrag
      (onOpenCCA)="toggleOpen($event, indexChild)"
    >
      <div class="custom-placeholder" *cdkDragPlaceholder></div>

      <img
        class="grab p-left handler"
        handler
        cdkDragHandle
        [src]="extraClass.IconsHandlerService.getDragableIcon()"
        alt=""
      />
    </cca-line>
    } }
  </section-line>
  }

  <custom-button
    style="width: fit-content"
    [options]="extraClass.addSectionButton"
    (click)="onAddSectionClick()"
  />
</div>
