import { iOptionsCustomInput } from "@quasar-dynamics/ngx-qd-design-system"
import { IconsHandlerService } from "../../Services/Utils/IconsHandler.service"
import { iButtonOptions } from "../Interfaces/Utils/iButtonOptions"
import { Icons } from "../Components/main-table/main-table.component"
import { Section } from "../Interfaces/Utils/iComponentTypeByIdToEdit";

export class SubsectionLineClass {

    constructor(
        private assureDelete:(element:string, id:number) => void
    ){}

    _isOpen:boolean | undefined = false
    _subsection: Section = {
        children: [],
        id: 0,
        name: '',
        sequence: 0,
        type: 'subsection',
      };

    IconsHandlerService = IconsHandlerService

    showInside:boolean = false

    // Custom input options
    sectionNameCustomInputOptions:iOptionsCustomInput = {
        placeholder:'Nombre de la sección',
        type:'text',
        classList:'dark-input',
    }
    subsectionNameCustomInputOptions:iOptionsCustomInput = {
        placeholder:'Nombre de la subsección',
        type:'text',
        classList:'dark-input',
    }

    // button options
    ccaButtonOptions:iButtonOptions = {
        class: 'quaternary',
        text: 'CCA',
        icon:{
            icon:true,
            image:IconsHandlerService.getAddCyanIcon()
        }
    }
    subsectionButtonOptions:iButtonOptions = {
        class: 'quintary',
        text: 'Subsección',
        icon:{
            icon:true,
            image:IconsHandlerService.getAddCyanIcon()
        }
    }

    // iconsSection
    iconsSection:Icons['icons'] = [
        {
            action: (item) => this.assureDelete( `la subsección ${item.name}`,item.id),
            image: IconsHandlerService.getDeleteBluePaleIcon(),
        }
    ]


}
