import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { IconsHandlerService } from '../../../Services/Utils/IconsHandler.service';
import { iTableTicketOptions } from '../../Interfaces/Utils/iTableTicketOptions';

@Component({
  selector: 'table-tick',
  templateUrl: './table-tick.component.html',
  styleUrls: ['./table-tick.component.scss']
})
export class TableTickComponent implements OnInit {

  private _options: iTableTicketOptions = {ticked:true, else:'-'}

  @Input() get options(){
    return this._options
  }
  set options(value){
    this._options = value
  }

  IconsHandlerService = IconsHandlerService

  constructor(private cdr:ChangeDetectorRef) { }

  ngOnInit() {
  }

}

