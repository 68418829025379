import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AssertCopyPopupClass } from '../../Shared/Class/AssertCopyPopupClass';

@Component({
  selector: 'Assert-Copy-Popup',
  templateUrl: './Assert-Copy-Popup.component.html',
  styleUrls: ['./Assert-Copy-Popup.component.scss'],
})
export class AssertCopyPopupComponent implements OnInit {
  extraClass: AssertCopyPopupClass = new AssertCopyPopupClass();
  constructor(
    public dialogRef: MatDialogRef<AssertCopyPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public modalData: any
  ) {}

  ngOnInit() {}

  /**
   * FUNCTIONALITY
   */
  closePopup(returnValue?: any) {
    let p = { returnValue: returnValue };
    this.dialogRef.close(p);
  }
}
