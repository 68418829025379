<div class="addFileContainer" [(ngModel)]="file" class="dragAndDrop" fileInput [accept]="acceptedFileTypesArray"
  [multiple]="multiple" (dragenter)="dragEnter()" (dragleave)="dragLeave()" (change)="updateFile(file)">
  <ng-container *ngIf="!isDragging">
    <div class="iconBox">
      <img [src]="IconsHandlerService.getUploadBluePaleIcon()" alt="uploadIcon">
    </div>
    <div class="namesLine">
      @if(filesArray.length > 0){
     <ng-container  *ngFor="let file of filesArray;last as last">
        <p>
           {{file.file.name}},
          </p> 
      </ng-container>
    } @else {
      <p>
        Arrastra y suelta el documento
      </p>
    }
    </div>
  </ng-container>
  <ng-container *ngIf="isDragging">
    <p class="dropText">Suelta tu fichero aquí</p>
  </ng-container>


</div>