import { Component, Input, OnInit } from '@angular/core';
import {
  iOptionsCustomInput,
  iResultHttp,
  StaticUtilitiesService,
} from '@quasar-dynamics/ngx-qd-design-system';
import { IconsHandlerService } from '../../../Services/Utils/IconsHandler.service';
import { DefaultDependenciesInjectorService } from '../../../Services/Utils/default-dependencies-injector.service';
import { LabService } from '../../../Services/Api/Lab.service';
import { AssureService } from '../../../Services/Utils/Assure.service';
import { filter, takeUntil } from 'rxjs';

@Component({
  selector: 'add-to-list-input',
  templateUrl: './add-to-list-input.component.html',
  styleUrls: ['./add-to-list-input.component.scss'],
})
export class AddToListInputComponent implements OnInit {
  @Input() customInputOptions: iOptionsCustomInput = {
    placeholder: 'Laboratorios/Evaluación',
    label: 'Laboratorios/Evaluación',
    type: 'text',
    classList: 'dark-input',
  };
  @Input({ required: true }) labs: { id: number; name: string }[] = [];
  @Input({ required: true }) componentId: number = 0;
  model: string = '';
  IconsHandlerService = IconsHandlerService;
  constructor(
    private DDIS: DefaultDependenciesInjectorService,
    private labSE: LabService,
    private assureSE: AssureService
  ) {}

  ngOnInit() {}

  /**
   * HANDLERS
   */

  successDeleteLabCallHandler(res: iResultHttp) {
    const { data } = res;
    this.labs = data;
    this.DDIS.generalLoaderSE.removeFromLoaderAmount();
  }

  successCreateLabHandler(res: iResultHttp) {
    const { data } = res;
    this.labs = data;
    this.DDIS.generalLoaderSE.removeFromLoaderAmount();
  }

  /**
   * FUNCTIONALITIES
   */

  onEnterPressed(model: string) {
    const objectToPass = {
      name: model,
      component: this.componentId,
    };
    this.createLab(objectToPass);
    this.model = '';
  }

  deleteLab(id: number) {
    this.assureSE.assureDelete('el laboratorio', () => this.deleteLabCall(id));
  }

  deleteLabCall(id: number) {
    this.DDIS.generalLoaderSE.addToLoaderAmount();
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.labSE.delete(behaviorSubject, id);
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () => this.successDeleteLabCallHandler(res),
          },
          {
            method: () => this.DDIS.generalLoaderSE.removeFromLoaderAmount(),
            error: true,
          },
        ]);
      });
  }

  createLab(objectToPass: any) {
    this.DDIS.generalLoaderSE.addToLoaderAmount();
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.labSE.create(behaviorSubject, objectToPass);
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () => this.successCreateLabHandler(res),
          },
          {
            method: () => this.DDIS.generalLoaderSE.removeFromLoaderAmount(),
            error: true,
          },
        ]);
      });
  }
}
