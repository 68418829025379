import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'SiteContainer',
  templateUrl: './SiteContainer.component.html',
  styleUrls: ['./SiteContainer.component.scss'],
})
export class SiteContainerComponent implements OnInit {
  @Input() section: 'offer' | null = null;

  opened: boolean = false;

  constructor() {}

  ngOnInit() {}
}
