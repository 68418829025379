<div class="cca-line" [ngClass]="{
    active: extraClass.showInfoContainer,
    insideSection: isInside,
    isInSubsection: isInSubsection
  }">
  <div class="line-box" [ngClass]="{ active: extraClass.showInfoContainer }" (click)="toggleInfoContainer()">
    <div class="componentsFlex">
      <ng-content> </ng-content>

      <img (click)="$event.stopImmediatePropagation(); sendOpenPopup(cca)"
        [src]="extraClass.IconsHandlerService.getSwapIcon()" alt="swap" />

      <p>{{ cca.name }}</p>
    </div>

    <div class="componentsFlex">
      <icon-container [item]="cca" [icons]="extraClass.iconsSection" />
      <img [ngClass]="{ active: extraClass.showInfoContainer }" [src]="extraClass.IconsHandlerService.getOpenIcon()"
        alt="open" />
    </div>
  </div>

  <div id="wrapper" [ngClass]="{ active: extraClass.showInfoContainer }">
    <div class="openedInfo">
      <div class="formLine double">
        <customInput [(ngModel)]="cca.name" [options]="extraClass.ccaNameCustomInputOptions"
          (change)="checkIfElementIsFull(cca.name, 'name')"></customInput>
        <mat-checkbox [(ngModel)]="cca.isCC" (change)="onCheckboxChange($event, 'isCC')">CC</mat-checkbox>
      </div>

      <div id="wrapperCC" [ngClass]="{ active: cca.isCC }">
        <div class="openedInfo">
          <div class="formLine double">
            <div class="radioGroupAndLabel">
              <label>Tipo de verificación</label>
              <mat-radio-group [(ngModel)]="cca.verificationType"
                (change)="onRadioButtonChange($event, 'verificationType')">
                @for (button of extraClass.radioButtons; track button) {
                <mat-radio-button [value]="button">{{
                  button
                  }}</mat-radio-button>
                }
              </mat-radio-group>
            </div>

            <div class="formLine double">
              @if(isComponent){
              <customInput [(ngModel)]="cca.quantityAssayed" [options]="extraClass.quantityAssayed"
                (change)="prepareUpdate(cca.quantityAssayed, 'quantityAssayed')"></customInput>
              }
              <customInput [(ngModel)]="cca.acceptanceMethod" [options]="extraClass.acceptanceMethod" (change)="
                  prepareUpdate(cca.acceptanceMethod, 'acceptanceMethod')
                "></customInput>
            </div>
          </div>

          <div class="formLine double">
            <customInput [(ngModel)]="cca.samplingPlan" [options]="extraClass.planDeMuestreoCustomInputOptions"
              (change)="prepareUpdate(cca.samplingPlan, 'samplingPlan')">
            </customInput>
            <customInput [(ngModel)]="cca.testProcedure" [options]="extraClass.procedimientoDeEnsayoCustomInputOptions"
              (change)="prepareUpdate(cca.testProcedure, 'testProcedure')">
            </customInput>
          </div>

          <div class="formLine double">
            <customInput [(ngModel)]="cca.acceptanceRequirements"
              [options]="extraClass.criterioAceptacionCustomInputOptions" (change)="
                prepareUpdate(
                  cca.acceptanceRequirements,
                  'acceptanceRequirements'
                )
              "></customInput>
            <customInput [(ngModel)]="cca.documentationCA" [options]="extraClass.documentacionCACustomInputOptions"
              (change)="prepareUpdate(cca.documentationCA, 'documentationCA')">
            </customInput>
          </div>

          <div class="formLine double">
            <customInput [(ngModel)]="cca.obsLab" [options]="extraClass.obsLabCustomInputOptions"
              (change)="prepareUpdate(cca.obsLab, 'obsLab')"></customInput>
            <customInput [(ngModel)]="cca.observations" [options]="extraClass.observacionesCustomInputOptions"
              (change)="prepareUpdate(cca.observations, 'observations')">
            </customInput>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>