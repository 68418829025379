<div class="fullContainer">
  <Sidebar (resize)="opened = $event"></Sidebar>
  <div
    class="pageContainer"
    id="fullContainer"
    cdkScrollable
    [class.opened]="opened"
    [style.background]="'var(--blue-dark)'"
  >
    <ng-content></ng-content>
    @if(section === 'offer'){
    <color-offer></color-offer>
    }
  </div>
</div>
