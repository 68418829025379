<div class="documents-table">
  <div
    class="row"
    [ngClass]="{ compensateScroll: documents.length > 3, 'no-edit': isView }"
  >
    @if(!isView){
    <br />
    }
    <p>Documentos adjuntos</p>
    <p class="d-flex jc-center">Anexo</p>
    <div class="iconContainer" #iconContainer>
      <ng-container *ngFor="let icon of icons">
        <span
          class="iconContainer--box"
          (click)="$event.stopPropagation(); icon?.actionHeader(documents)"
        >
          <ng-container *ngIf="icon.imageHeader">
            <img src="{{ icon.imageHeader }}" />
          </ng-container>
        </span>
      </ng-container>
    </div>
  </div>
  <div cdkDropList (cdkDropListDropped)="onListDrop($event)" class="rowList">
    @for (document of documents; track document) {
    <div
      class="row body"
      [ngClass]="{ 'no-edit': isView }"
      cdkDrag
      (click)="onDocumentClick(document)"
    >
      <!-- Gragable icon -->
      @if(!isView){

      <div class="d-flex jc-center" cdkDragHandle>
        <img
          class="cursor-grab"
          [src]="IconsHandlerService.getDragableIcon()"
          alt="dragable icon"
        />
      </div>

      }
      <!-- Document name -->
      <p class="truncateText maxWidth-250">
        {{ document.name }}
      </p>

      <table-tick [options]="{ ticked: document.isAnnex, else: '-' }" />

      <div class="iconContainer" #iconContainer>
        <ng-container *ngFor="let icon of icons">
          <span
            class="iconContainer--box"
            [ngClass]="{
              disabled:
                icon.enableCondition !== undefined &&
                icon.enableCondition(document) === false
            }"
            (click)="$event.stopPropagation(); icon.action(document)"
          >
            <ng-container *ngIf="icon.matIconName">
              <mat-icon>{{ icon.matIconName }}</mat-icon>
            </ng-container>

            <ng-container *ngIf="icon.image">
              <img src="{{ icon.image }}" />
            </ng-container>
          </span>
        </ng-container>
      </div>
    </div>
    }
  </div>

  @if(!isView){

  <div
    class="row body cursor-pointer"
    style="border-bottom: rgba(139, 154, 184, 50%) 1px solid"
    (click)="onAddDocumentClick()"
  >
    <div class="iconBox">
      <span class="roundedIcon">
        <img [src]="IconsHandlerService.getAddCyanIcon()" alt="add" />
      </span>
    </div>
    <p class="blue-cyan">Añadir documento</p>
  </div>
  }
</div>
