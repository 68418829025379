import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  DefaultBaseService,
  iResultHttp,
} from '@quasar-dynamics/ngx-qd-design-system';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ArchiveService extends DefaultBaseService {
  res: iResultHttp = new iResultHttp();

  constructor(private http: HttpClient) {
    super();
  }

  create(behaviorSubject: BehaviorSubject<any>, data: any) {
    this.http.post(this.urlApi + '/archive', data, this.getHeader()).subscribe({
      next: (data: any) => {
        this.res = new iResultHttp({
          status: 200,
          ok: true,
          message: '',
          data: data.data,
        });
        this.sendNextObservable(behaviorSubject, this.res);
      },
      error: (error: any) => {
        this.res = new iResultHttp({
          status: error.status,
          ok: false,
          message: error.error.message,
        });
        this.sendNextObservable(behaviorSubject, this.res, true, {
          method: this.create,
          args: [behaviorSubject, data],
        });
      },
    });
  }

  update(behaviorSubject: BehaviorSubject<any>, data: any) {
    this.http
      .put(`${this.urlApi}/archive/${data.id}`, data, this.getHeader())
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: data.data,
          });
          this.sendNextObservable(behaviorSubject, this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextObservable(behaviorSubject, this.res, true, {
            method: this.update,
            args: [behaviorSubject, data],
          });
        },
      });
  }

  delete(behaviorSubject: BehaviorSubject<any>, id: number) {
    this.http
      .delete(`${this.urlApi}/archive/${id}`, this.getHeader())
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: data.data,
          });
          this.sendNextObservable(behaviorSubject, this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextObservable(behaviorSubject, this.res, true, {
            method: this.delete,
            args: [behaviorSubject, id],
          });
        },
      });
  }
}
