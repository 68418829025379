import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AssertDeletePopupClass } from '../../Shared/Class/AssertDeletePopupClass';

@Component({
  selector: 'Assert-Delete-Popup',
  templateUrl: './Assert-Delete-Popup.component.html',
  styleUrls: ['./Assert-Delete-Popup.component.scss'],
})
export class AssertDeletePopupComponent implements OnInit {

  extraClass:AssertDeletePopupClass = new AssertDeletePopupClass()
  constructor(
    public dialogRef: MatDialogRef<AssertDeletePopupComponent>,
    @Inject(MAT_DIALOG_DATA) public modalData: any
  ) {}

  ngOnInit() {}

  /**
   * FUNCTIONALITY
   */
  closePopup(returnValue?: any) {
    let p = { returnValue: returnValue };
    this.dialogRef.close(p);
  }
}
