import { iOptionsCustomInput } from "@quasar-dynamics/ngx-qd-design-system"
import { IconsHandlerService } from "../../Services/Utils/IconsHandler.service"
import { iButtonOptions } from "../Interfaces/Utils/iButtonOptions"
import { Icons } from "../Components/main-table/main-table.component"
import { TimeInterval } from "rxjs/internal/operators/timeInterval"
import { Subject } from "rxjs"
import { IComponentTypeByIdToEdit, iComponentTypeByIdToEdit } from "../Interfaces/Utils/iComponentTypeByIdToEdit"

export class SectionLineClass {

    constructor(
        private assureDelete:(element:string,id:number) => void
    ){}

    _section: iComponentTypeByIdToEdit['sections'][0] =
    IComponentTypeByIdToEdit.getEmptyObject().sections[0]

    intervalSubject:Subject<any> = new Subject()

    IconsHandlerService = IconsHandlerService

    // Custom input options
    sectionNameCustomInputOptions:iOptionsCustomInput = {
        placeholder:'Nombre de la sección',
        type:'text',
        classList:'dark-input',
    }

    // button options
    ccaButtonOptions:iButtonOptions = {
        class: 'quaternary',
        text: 'CCA',
        icon:{
            icon:true,
            image:IconsHandlerService.getAddCyanIcon()
        }
    }
    subsectionButtonOptions:iButtonOptions = {
        class: 'quintary',
        text: 'Subsección',
        icon:{
            icon:true,
            image:IconsHandlerService.getAddCyanIcon()
        }
    }

    // iconsSection
    iconsSection:Icons['icons'] = [
        {
            action:(item) => this.assureDelete(`la sección ${item.name}`,item.id), 
            image: IconsHandlerService.getDeleteBluePaleIcon(),
        }
    ]


}
