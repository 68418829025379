import {
  Component,
  ContentChildren,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  input,
} from '@angular/core';
import { SubsectionLineClass } from '../../Class/SubsectionLineClass';
import { Section } from '../../Interfaces/Utils/iComponentTypeByIdToEdit';
import { DefaultDependenciesInjectorService } from '../../../Services/Utils/default-dependencies-injector.service';
import { SubsectionService } from '../../../Services/Api/Subsection.service';
import {
  StaticUtilitiesService,
  iResultHttp,
} from '@quasar-dynamics/ngx-qd-design-system';
import { filter, takeUntil } from 'rxjs';
import { CCATypeService } from '../../../Services/Api/CCAType.service';
import { CcaLineComponent } from '../cca-line/cca-line.component';
import { AssureService } from '../../../Services/Utils/Assure.service';
import { CheckIsComponentTypeCompleteService } from '../../../Services/Utils/CheckIsComponentTypeComplete.service';
import { FieldCCAService } from '../../../Services/Api/FieldCCA.service';

@Component({
  selector: 'subsection-line',
  templateUrl: './subsection-line.component.html',
  styleUrls: ['./subsection-line.component.scss'],
})
export class SubsectionLineComponent implements OnInit {
  @ContentChildren(CcaLineComponent) ccas!: QueryList<CcaLineComponent>;

  @Output() open: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() recall: EventEmitter<any> = new EventEmitter<any>();
  @Output() moveTo: EventEmitter<Section> = new EventEmitter<Section>();
  @Output() onOpen: EventEmitter<Section> = new EventEmitter<Section>();

  @Input() last: boolean = false;
  @Input() isComponent: boolean = false;
  @Input() get isOpen() {
    return this.extraClass._isOpen;
  }
  set isOpen(value: boolean | undefined) {
    this.extraClass._isOpen = value;
    if (value) this.toggleShowInside();
  }

  @Input() get subsection() {
    return this.extraClass._subsection;
  }
  set subsection(value: Section) {
    this.extraClass._subsection = value;
    this.checkIsCompleteHandler(value.name);
  }

  extraClass: SubsectionLineClass = new SubsectionLineClass(
    this.assureDelete.bind(this)
  );

  constructor(
    private DDIS: DefaultDependenciesInjectorService,
    private subsectionSE: SubsectionService,
    private CCASE: CCATypeService,
    private assureSE: AssureService,
    private checkIsCompleteSE: CheckIsComponentTypeCompleteService,
    private fieldCCASE: FieldCCAService
  ) {}

  ngOnInit() {}

  /***
   * API CALLS HANDLER
   */

  successUpdatesubsectionHandler(res: iResultHttp) {
    this.DDIS.generalLoaderSE.removeFromLoaderAmount();
  }
  successDeleteSubsectionHandler(res: iResultHttp) {
    this.recall.emit(true);
    this.DDIS.generalLoaderSE.removeFromLoaderAmount();
  }
  successCreateCCAHandler(res: iResultHttp) {
    this.recall.emit(true);
    this.DDIS.generalLoaderSE.removeFromLoaderAmount();
  }

  successCreateFieldCCAHandler(res: iResultHttp) {
    this.recall.emit(true);
    this.DDIS.generalLoaderSE.removeFromLoaderAmount();
  }

  /**
   * FUNCTIONALITIES
   */
  toggleShowInside() {
    this.extraClass.showInside = !this.extraClass.showInside;
    this.onOpen.emit(this.subsection);
    this.open.emit(true);
  }

  onChangeToUpdate(value: any, key: string) {
    const objectToPass = {
      id: this.subsection.id,
      [key]: value,
    };
    this.updatesubsection(objectToPass);
  }

  onclickCreateCCA() {
    const objectToPass = {
      subsection: this.subsection.id,
      name: 'Nueva CCA',
    };
    if (this.isComponent) {
      this.createFieldCCA(objectToPass);
      return;
    }
    this.createCCA(objectToPass);
  }

  assureDelete(element: string, id: number) {
    this.assureSE.assureDelete(element, () => this.deleteSubsection(id));
  }
  sendOpenPopup(subsection: Section) {
    this.moveTo.emit(subsection);
  }

  checkIsCompleteHandler(value: any) {
    this.checkIsCompleteSE.setElement(
      this.subsection.id,
      null !== value && undefined !== value && '' !== value && false !== value
    );
  }

  checkIfElementIsFull(value: any, key: string) {
    if (!value) {
      StaticUtilitiesService.showWarning('No puedes dejar este campo vacío');
      return;
    }
    this.onChangeToUpdate(value, key);
    this.checkIsCompleteHandler(value);
  }

  /***
   * API CALLS
   */

  updatesubsection(objectToPass: any) {
    this.DDIS.generalLoaderSE.addToLoaderAmount();
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.subsectionSE.update(behaviorSubject, objectToPass);
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () => this.successUpdatesubsectionHandler(res),
          },
          {
            method: () => this.DDIS.generalLoaderSE.removeFromLoaderAmount(),
            error: true,
          },
        ]);
      });
  }

  deleteSubsection(id: number) {
    this.DDIS.generalLoaderSE.addToLoaderAmount();
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.subsectionSE.delete(behaviorSubject, id);
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () => this.successDeleteSubsectionHandler(res),
          },
          {
            method: () => this.DDIS.generalLoaderSE.removeFromLoaderAmount(),
            error: true,
          },
        ]);
      });
  }

  createCCA(objectToPass: any) {
    this.DDIS.generalLoaderSE.addToLoaderAmount();
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.CCASE.create(behaviorSubject, objectToPass);
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () => this.successCreateCCAHandler(res),
          },
          {
            method: () => this.DDIS.generalLoaderSE.removeFromLoaderAmount(),
            error: true,
          },
        ]);
      });
  }

  createFieldCCA(objectToPass: any) {
    this.DDIS.generalLoaderSE.addToLoaderAmount();
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.fieldCCASE.create(behaviorSubject, objectToPass);
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () => this.successCreateFieldCCAHandler(res),
          },
          {
            method: () => this.DDIS.generalLoaderSE.removeFromLoaderAmount(),
            error: true,
          },
        ]);
      });
  }
}
