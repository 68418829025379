import { AfterContentInit, Directive, ElementRef, Input, OnChanges, OnInit, Renderer2, SimpleChanges, ViewChild } from '@angular/core';

@Directive({
  selector: '[skeleton]'
})
export class SkeletonDirective implements OnInit, OnChanges {

  @Input() isDataLoaded: boolean = true;

  constructor(private elemRef: ElementRef, private _renderer: Renderer2) {}
  ngOnChanges(changes: SimpleChanges): void {
    if (!this.isDataLoaded) {
      if (this.elemRef.nativeElement.querySelector("card") != null) {
        this.elemRef.nativeElement.firstChild.firstChild.classList.add("skeleton")
      }else{
        this.elemRef.nativeElement.classList.add("skeleton")

      }
    }else{
      if (this.elemRef.nativeElement.querySelector("card") != null) {
        this.elemRef.nativeElement.firstChild.firstChild.classList.remove("skeleton")
      }else{
        this.elemRef.nativeElement.classList.remove("skeleton")

      }
    }
  }
  ngOnInit(): void {
  }



}
