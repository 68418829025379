<div class="popupGeneralContainer">
  <div class="header">
    <mat-icon (click)="closePopup(false)">close</mat-icon>
  </div>
  <div class="body">
    <customTextArea
      style="width: 100%"
      [options]="observationsCustomInputOptions"
      [(ngModel)]="observations"
    ></customTextArea>
  </div>

  <div class="footer"></div>
</div>
