import { iOptionsCustomInput } from "@quasar-dynamics/ngx-qd-design-system";
import { iBase64File } from "../Interfaces/Utils/iBase64File";
import { iButtonOptions } from "../Interfaces/Utils/iButtonOptions";
import { IconsHandlerService } from "../../Services/Utils/IconsHandler.service";

export class AddDocumentPopupClass {

    isCompleteObject: boolean = false

    ngModels:{
        name:string|null,
        annex:boolean,
        observations:string|null | undefined,
        base64:string|null,
    
    } = {
        name: null,
        annex: false,
        observations: null,
        base64: null,
    }

    IconsHandlerService = IconsHandlerService

    // acceptedFileTypesArray
    acceptedFileTypesArray:string[] = ['application/pdf']

    // maxFileSizeMB
    maxFileSizeMB:number = 10

    // filesArray
    filesArray:iBase64File[] = []

    // Custom input options
    documentNameCustomInputOptions:iOptionsCustomInput = {
        placeholder: 'Nombre de documento',
        label: 'Nombre de documento',
        type: 'text',
        classList: 'dark-input',
        required: true,
    }
    observationsCustomInputOptions:iOptionsCustomInput = {
        placeholder: 'Observaciones',
        label: 'Observaciones',
        type: 'text',
        classList: 'dark-input',
    }

    // ButtonOptions
    cancelButtonOptions:iButtonOptions = {
        class: "tertiary",
        icon:{
            icon:true,
            image: this.IconsHandlerService.getCancelSkyBlueIcon()
        },
        text: "Cancelar"
    }
    saveButtonOptions:iButtonOptions = {
        class: "primary",
        icon:{
            icon:true,
            image: this.IconsHandlerService.getTickIcon()
        },
        text: "Guardar"
    }

}
