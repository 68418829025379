import { Injectable } from '@angular/core';
import {
  GeneralLoaderService,
  PopupService,
  StaticUtilitiesService,
} from '@quasar-dynamics/ngx-qd-design-system';

@Injectable({
  providedIn: 'root',
})
export class DefaultDependenciesInjectorService {
  constructor(
    public generalLoaderSE: GeneralLoaderService,
    public staticUtilitiesSE: StaticUtilitiesService,
    public popupSE: PopupService
  ) {}
}
