import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ComponentTypeTreeClass } from '../../Class/ComponentTypeTreeClass';
import {
  CCA,
  IComponentTypeByIdToEdit,
  Section,
  iComponentTypeByIdToEdit,
} from '../../Interfaces/Utils/iComponentTypeByIdToEdit';
import { DefaultComponentDependenciesInjector } from '../../Class/default-component-dependencies-injector';
import { SectionService } from '../../../Services/Api/Section.service';
import { filter, takeUntil } from 'rxjs';
import {
  StaticUtilitiesService,
  iResultHttp,
} from '@quasar-dynamics/ngx-qd-design-system';
import { DefaultDependenciesInjectorService } from '../../../Services/Utils/default-dependencies-injector.service';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { CCATypeService } from '../../../Services/Api/CCAType.service';
import { ReorderService } from '../../../Services/Api/Reorder.service';
import { MoveToPopupComponent } from '../../../Popups/Move-To-Popup/Move-To-Popup.component';
import { SubsectionService } from '../../../Services/Api/Subsection.service';
import { CheckIsComponentTypeCompleteService } from '../../../Services/Utils/CheckIsComponentTypeComplete.service';
import { FieldCCAService } from '../../../Services/Api/FieldCCA.service';

@Component({
  selector: 'component-type-tree',
  templateUrl: './component-type-tree.component.html',
  styleUrls: ['./component-type-tree.component.scss'],
})
export class ComponentTypeTreeComponent implements OnInit {
  @Output() recall: EventEmitter<any> = new EventEmitter();

  @Input({ required: true })
  get sections(): iComponentTypeByIdToEdit['sections'] {
    return this.extraClass.sections;
  }
  set sections(value: iComponentTypeByIdToEdit['sections']) {
    this.extraClass.sections = value;
    if (value.length > 0) {
      this.checkOpen(value);
    }
  }

  @Input({ required: true }) componentTypeId: number = 0;
  @Input() isComponent: boolean = false;

  extraClass: ComponentTypeTreeClass = new ComponentTypeTreeClass();

  constructor(
    private DDIS: DefaultDependenciesInjectorService,
    private sectionSE: SectionService,
    private CCASE: CCATypeService,
    private reorderSE: ReorderService,
    private subsectionSE: SubsectionService,
    private fieldCCASE: FieldCCAService
  ) {}

  ngOnInit() {}

  /***
   * HANDLERS
   */

  successCreateSectionHandler(res: iResultHttp) {
    const { data } = res;
    this.sections = data;
    this.DDIS.generalLoaderSE.removeFromLoaderAmount();
  }
  successCreateCCAHandler(res: iResultHttp) {
    this.recall.emit(true);
    this.DDIS.generalLoaderSE.removeFromLoaderAmount();
  }
  successReorderItemsHandler(res: iResultHttp) {
    this.recall.emit(true);
    this.DDIS.generalLoaderSE.removeFromLoaderAmount();
  }
  successUpdateCCATypeHandler(res: iResultHttp) {
    this.recall.emit(true);
    this.DDIS.generalLoaderSE.removeFromLoaderAmount();
  }
  successUpdateSubsectionHandler(res: iResultHttp) {
    this.recall.emit(true);
    this.DDIS.generalLoaderSE.removeFromLoaderAmount();
  }
  successCreateFieldCCAHandler(res: iResultHttp) {
    this.recall.emit(true);
    this.DDIS.generalLoaderSE.removeFromLoaderAmount();
  }
  successUpdateFieldCCAHandler(res: iResultHttp) {
    this.recall.emit(true);
    this.DDIS.generalLoaderSE.removeFromLoaderAmount();
  }

  /***
   * FUNCTIONALITIES
   */

  onAddSectionClick() {
    const objectToPass: any = {
      name: 'Nueva sección',
    };
    if (this.isComponent)
      objectToPass['component'] = Number(this.componentTypeId);
    if (!this.isComponent)
      objectToPass['componentType'] = Number(this.componentTypeId);
    this.createSection(objectToPass);
  }

  checkOpen(value: iComponentTypeByIdToEdit['sections']) {
    value.forEach((section) => {
      section.children.forEach((child) => {
        const elementIndex = this.extraClass.openedElements.findIndex(
          (openedElement) =>
            openedElement.id === child.id && openedElement.type === child.type
        );
        if (elementIndex !== -1) child.isOpen = true;

        if (child.type === 'subsection' && child.children.length > 0) {
          child.children.forEach((cca) => {
            const elementIndex = this.extraClass.openedElements.findIndex(
              (openedElement) =>
                openedElement.id === cca.id && openedElement.type === cca.type
            );
            if (elementIndex !== -1) cca.isOpen = true;
          });
        }
      });
    });
  }

  onRecall() {
    this.recall.emit();
  }

  returnIfCCA(cca: CCA | Section): CCA {
    if (cca.type === 'cca' || cca.type === 'ccaType') {
      return cca as CCA;
    } else {
      return IComponentTypeByIdToEdit.getEmptyCCA();
    }
  }

  drop($event: CdkDragDrop<any, any, any>, array: any[]) {
    StaticUtilitiesService.moveItemInArray(
      array,
      $event.previousIndex,
      $event.currentIndex
    );
    this.reorderElements(array);
  }

  reorderElements(array: any[]) {
    const objectToPass = array.map((element, index) => {
      return {
        id: element.id,
        sequence: index + 1,
        type: element.type,
      };
    });
    this.reorderItems(objectToPass);
  }

  onclickCreateCCA(subsection: any) {
    const objectToPass = {
      subsection: subsection.id,
      name: 'Nueva CCA',
    };
    if (!this.isComponent) this.createCCA(objectToPass);
    if (this.isComponent) this.createFieldCCA(objectToPass);
  }

  openMoveToPopup(item: Section | CCA) {
    const subject = StaticUtilitiesService.createSubject();
    this.DDIS.popupSE.openPopup(MoveToPopupComponent, {
      id: item.id,
      type: item.type,
      componentTypeId: this.componentTypeId,
      isComponent: this.isComponent,
    });
    this.DDIS.popupSE
      .returnData()
      .pipe(takeUntil(subject))
      .subscribe((res) => {
        const returnValue: iReturnValueMoveObject = res?.returnValue;
        if (returnValue === undefined) return;
        if (returnValue) {
          this.handlePopupResponse(item, returnValue);
        }
        subject.next(true);
        subject.complete();
      });
  }

  handlePopupResponse(
    item: Section | CCA,
    returnValue: iReturnValueMoveObject
  ) {
    if (!returnValue.section && !returnValue.subsection) return;
    const objectToPass: iObjectToPassMoveObject = {
      id: item.id,
      ...(returnValue.section &&
        !returnValue.subsection && { section: returnValue.section }),
      ...(returnValue.subsection && { subsection: returnValue.subsection }),
    };

    switch (item.type) {
      case 'subsection':
        this.updateSubsection(objectToPass);
        break;
      case 'cca':
        this.updateFieldCCA(objectToPass);
        break;
      case 'ccaType':
        this.updateCCAType(objectToPass);
        break;
    }
  }

  toggleOpen(element: Section | CCA, index: number) {
    if (element.type === 'section') return;
    const id = element.id;
    const type: 'subsection' | 'cca' | 'ccaType' = element.type;
    const objectIsOpen = { type, id };
    const elementIndex = this.extraClass.openedElements.findIndex(
      (el) => el.id === id && el.type === type
    );
    if (elementIndex === -1) {
      this.extraClass.openedElements.push(objectIsOpen);
    } else {
      this.extraClass.openedElements.splice(elementIndex, 1);
    }
  }

  /***
   * API CALLS
   */

  createSection(objectToPass: any) {
    this.DDIS.generalLoaderSE.addToLoaderAmount();
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.sectionSE.create(behaviorSubject, objectToPass);
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () => this.successCreateSectionHandler(res),
          },
          {
            method: () => this.DDIS.generalLoaderSE.removeFromLoaderAmount(),
            error: true,
          },
        ]);
      });
  }

  createCCA(objectToPass: any) {
    this.DDIS.generalLoaderSE.addToLoaderAmount();
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.CCASE.create(behaviorSubject, objectToPass);
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () => this.successCreateCCAHandler(res),
          },
          {
            method: () => this.DDIS.generalLoaderSE.removeFromLoaderAmount(),
            error: true,
          },
        ]);
      });
  }

  reorderItems(objectToPass: any) {
    this.DDIS.generalLoaderSE.addToLoaderAmount();
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.reorderSE.update(behaviorSubject, objectToPass);
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () => this.successReorderItemsHandler(res),
          },
          {
            method: () => this.DDIS.generalLoaderSE.removeFromLoaderAmount(),
            error: true,
          },
        ]);
      });
  }

  updateCCAType(objectToPass: any) {
    this.DDIS.generalLoaderSE.addToLoaderAmount();
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.CCASE.update(behaviorSubject, objectToPass);
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () => this.successUpdateCCATypeHandler(res),
          },
          {
            method: () => this.DDIS.generalLoaderSE.removeFromLoaderAmount(),
            error: true,
          },
        ]);
      });
  }

  updateSubsection(objectToPass: any) {
    this.DDIS.generalLoaderSE.addToLoaderAmount();
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.subsectionSE.update(behaviorSubject, objectToPass);
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () => this.successUpdateSubsectionHandler(res),
          },
          {
            method: () => this.DDIS.generalLoaderSE.removeFromLoaderAmount(),
            error: true,
          },
        ]);
      });
  }

  createFieldCCA(objectToPass: any) {
    this.DDIS.generalLoaderSE.addToLoaderAmount();
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.fieldCCASE.create(behaviorSubject, objectToPass);
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () => this.successCreateFieldCCAHandler(res),
          },
          {
            method: () => this.DDIS.generalLoaderSE.removeFromLoaderAmount(),
            error: true,
          },
        ]);
      });
  }

  updateFieldCCA(objectToPass: any) {
    this.DDIS.generalLoaderSE.addToLoaderAmount();
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.fieldCCASE.update(behaviorSubject, objectToPass);
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () => this.successUpdateFieldCCAHandler(res),
          },
          {
            method: () => this.DDIS.generalLoaderSE.removeFromLoaderAmount(),
            error: true,
          },
        ]);
      });
  }
}

interface iObjectToPassMoveObject {
  id: number;
  section?: number | null;
  subsection?: number | null;
}
interface iReturnValueMoveObject {
  section: number | null;
  subsection: number | null;
}
