import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IconsHandlerService } from '../../../Services/Utils/IconsHandler.service';
import { iButtonOptions } from '../../Interfaces/Utils/iButtonOptions';

@Component({
  selector: 'custom-button',
  templateUrl: './custom-button.component.html',
  styleUrls: ['./custom-button.component.scss']
})
export class CustomButtonComponent implements OnInit {

  @Output() onClick = new EventEmitter<any>();

  @Input({required: true}) options!: iButtonOptions;

  IconsHandlerService = IconsHandlerService;

  constructor() { }

  ngOnInit() {
  }

  sendOnClick(){
    this.onClick.emit();
  }

}
