<div class="popupGeneralContainer">
  <div class="header">
    Mover elemento 
  </div>
  <div class="body" >
    <div class="formLine">
      <selector [class.disabled]="extraClass.ngModels.subsection" style="width: 100%;" [(ngModel)]="extraClass.ngModels.section" [options]="extraClass.sectionSelectorOptions" (change)="getSubsectionSelector(extraClass.ngModels.section)"></selector>
    </div>

    @if(modalData.type === 'cca'){

      <div class="formLine">
        <selector [class.disabled]="!extraClass.ngModels.section" style="width: 100%;" [(ngModel)]="extraClass.ngModels.subsection" [options]="extraClass.subsectionSelectorOptions"></selector>
      </div>
    
    }
  </div>

  <div class="footer">
    <custom-button [options]="extraClass.cancelButtonOptions" (click)="closePopup(false)" />
    <custom-button [class.disabled]="!extraClass.ngModels.section" [options]="extraClass.saveButtonOptions" (click)="onSaveButtonClick()" />
  </div>
</div>
