import { Component, OnInit } from '@angular/core';
import { IconsHandlerService } from '../../../Services/Utils/IconsHandler.service';

@Component({
  selector: 'go-back',
  templateUrl: './go-back.component.html',
  styleUrls: ['./go-back.component.scss']
})
export class GoBackComponent implements OnInit {

  IconsHandlerService = IconsHandlerService

  constructor() { }

  ngOnInit() {
  }

}
