<div class="sidebarGeneralContainer" [class.opened]="isOpened">
  <div class="menu">
    <span class="logo">
      <a [routerLink]="['/component/main-view']">
        <img
          [src]="IconsHandlerService.getNucleoNovaIcon()"
          alt="nucloNova-icon"
        />
      </a>
    </span>
    <div class="subnav">
      <a class="subnav_item" [routerLink]="['/component/main-view']">
        <img
          [src]="IconsHandlerService.getComponentsIcon()"
          alt="componentesIcon"
        />
        <!-- <span class="itemTitle" [class.opened]="isOpened">Formaciones</span> -->
        <span class="tooltip"> Componentes </span>
      </a>
      <a class="subnav_item" [routerLink]="['/offer/main-view']">
        <img
          [src]="IconsHandlerService.getOfferSidebarIcon()"
          alt="getOfferSidebarIcon"
        />
        <!-- <span class="itemTitle" [class.opened]="isOpened">Formaciones</span> -->
        <span class="tooltip"> Ofertas </span>
      </a>
    </div>
  </div>
</div>
