import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class IconsHandlerService {
  private static nucleoNovaIcon = '/assets/Icons/logo-nucleonova.png';
  private static componentsIcon = '/assets/Icons/componentsIcon.svg';
  private static addCyanIcon = '/assets/Icons/add-cyan.svg';
  private static addDarkBlueIcon = '/assets/Icons/add-dark-blue.svg';
  private static addSkyBlueIcon = '/assets/Icons/add-sky.svg';
  private static tickIcon = '/assets/Icons/tickIcon.svg';
  private static eyeBluePaleIcon = '/assets/Icons/eyeBluePaleIcon.svg';
  private static editBluePaleIcon = '/assets/Icons/editBluePaleIcon.svg';
  private static deleteBluePaleIcon = '/assets/Icons/deleteBluePaleIcon.svg';
  private static arrowBackIcon = '/assets/Icons/arrowBackIcon.svg';
  private static dragableIcon = '/assets/Icons/dragableIcon.svg';
  private static downloadIcon = '/assets/Icons/downloadIcon.svg';
  private static downloadBluePaleIcon =
    '/assets/Icons/downloadBluePaleIcon.svg';
  private static downloadWhiteIcon = '/assets/Icons/downloadWhiteIcon.svg';
  private static uploadBluePaleIcon = '/assets/Icons/uploadBluePaleIcon.svg';
  private static cancelSkyBlueIcon = '/assets/Icons/cancelSkyBlueIcon.svg';
  private static deleteBlueCyanIcon = '/assets/Icons/delete-BlueCyanIcon.svg';
  private static copyBluePaleIcon = '/assets/Icons/copyBluePaleIcon.svg';
  private static openIcon = '/assets/Icons/openIcon.svg';
  private static swapIcon = '/assets/Icons/swapIcon.svg';
  private static offerSidebarIcon = '/assets/Icons/offerSidebarIcon.svg';
  private static uploadDarkBlueIcon = '/assets/Icons/uploadDarkBlueIcon.svg';
  private static editDarkBlueIcon = '/assets/Icons/editDarkBlueIcon.svg';
  private static dialogBluePaleIcon = '/assets/Icons/dialogBluePaleIcon.svg';
  private static addSkyBlueIconPNG = '/assets/Icons/addSkyBlueIcon.svg';
  private static roundedCyanAddIcon = '/assets/Icons/roundedCyanAddIcon.svg';
  constructor() {}

  static getComponentsIcon(): string {
    return this.componentsIcon;
  }
  static getNucleoNovaIcon(): string {
    return this.nucleoNovaIcon;
  }
  static getAddCyanIcon(): string {
    return this.addCyanIcon;
  }
  static getAddDarkBlueIcon(): string {
    return this.addDarkBlueIcon;
  }
  static getTickIcon(): string {
    return this.tickIcon;
  }
  static getEyeBluePaleIcon(): string {
    return this.eyeBluePaleIcon;
  }
  static getEditBluePaleIcon(): string {
    return this.editBluePaleIcon;
  }
  static getDeleteBluePaleIcon(): string {
    return this.deleteBluePaleIcon;
  }
  static getCopyBluePaleIcon(): string {
    return this.copyBluePaleIcon;
  }
  static getArrowBackIcon(): string {
    return this.arrowBackIcon;
  }
  static getDragableIcon(): string {
    return this.dragableIcon;
  }
  static getDownloadIcon(): string {
    return this.downloadIcon;
  }
  static getDownloadBluePaleIcon(): string {
    return this.downloadBluePaleIcon;
  }
  static getDownloadWhiteIcon(): string {
    return this.downloadWhiteIcon;
  }
  static getUploadBluePaleIcon(): string {
    return this.uploadBluePaleIcon;
  }
  static getCancelSkyBlueIcon(): string {
    return this.cancelSkyBlueIcon;
  }
  static getDeleteBlueCyanIcon(): string {
    return this.deleteBlueCyanIcon;
  }
  static getOpenIcon(): string {
    return this.openIcon;
  }
  static getSwapIcon(): string {
    return this.swapIcon;
  }
  static getOfferSidebarIcon(): string {
    return this.offerSidebarIcon;
  }
  static getUploadDarkBlueIcon(): string {
    return this.uploadDarkBlueIcon;
  }
  static getEditDarkBlueIcon(): string {
    return this.editDarkBlueIcon;
  }
  static getDialogBluePaleIcon(): string {
    return this.dialogBluePaleIcon;
  }
  static getAddSkyBlueIcon(): string {
    return this.addSkyBlueIcon;
  }
  static getAddSkyBlueIconPNG(): string {
    return this.addSkyBlueIconPNG;
  }
  static getRoundedCyanAddIcon(): string {
    return this.roundedCyanAddIcon;
  }
}
