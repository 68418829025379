<div class="popupGeneralContainer">
  <div class="header">
  </div>
  <div class="body">
    <p>¿Estás seguro de que deseas copiar {{modalData.element}}?</p>
  </div>

  <div class="footer">
    <custom-button [options]="extraClass.cancelButtonOptions" (click)="closePopup(false)" />
    <custom-button [options]="extraClass.deleteButtonOptions" (click)="closePopup(true)" />
  </div>
</div>