import {
  Component,
  ContentChildren,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChild,
} from '@angular/core';
import { SectionLineClass } from '../../Class/SectionLineClass';
import { SubsectionLineComponent } from '../subsection-line/subsection-line.component';
import { CcaLineComponent } from '../cca-line/cca-line.component';
import { filter, take, takeUntil, timer } from 'rxjs';
import {
  IComponentTypeByIdToEdit,
  iComponentTypeByIdToEdit,
} from '../../Interfaces/Utils/iComponentTypeByIdToEdit';
import { DefaultDependenciesInjectorService } from '../../../Services/Utils/default-dependencies-injector.service';
import { SectionService } from '../../../Services/Api/Section.service';
import {
  StaticUtilitiesService,
  iResultHttp,
} from '@quasar-dynamics/ngx-qd-design-system';
import { SubsectionService } from '../../../Services/Api/Subsection.service';
import { CCATypeService } from '../../../Services/Api/CCAType.service';
import { AssureService } from '../../../Services/Utils/Assure.service';
import { CheckIsComponentTypeCompleteService } from '../../../Services/Utils/CheckIsComponentTypeComplete.service';
import { FieldCCAService } from '../../../Services/Api/FieldCCA.service';

@Component({
  selector: 'section-line',
  templateUrl: './section-line.component.html',
  styleUrls: ['./section-line.component.scss'],
})
export class SectionLineComponent implements OnInit {
  @ContentChildren(SubsectionLineComponent)
  subsections!: QueryList<SubsectionLineComponent>;
  @ContentChildren(CcaLineComponent) ccas!: QueryList<CcaLineComponent>;

  @ViewChild('sectionContainer') sectionContainer!: ElementRef;
  @ViewChild('leftLineVertical') leftLineVertical!: ElementRef;

  @Output() recall: EventEmitter<any> = new EventEmitter();

  @Input({ required: true })
  get section() {
    return this.extraClass._section;
  }
  set section(value: iComponentTypeByIdToEdit['sections'][0]) {
    this.extraClass._section = value;
    this.checkIsCompleteHandler(value.name);
    setTimeout(() => {
      // this.calculateVerticalLineHeight();
    }, 500);
  }
  @Input() isComponent: boolean = false;

  extraClass: SectionLineClass = new SectionLineClass(
    this.assureDelete.bind(this)
  );

  constructor(
    private DDIS: DefaultDependenciesInjectorService,
    private sectionSE: SectionService,
    private subsectionSE: SubsectionService,
    private CCASE: CCATypeService,
    private assureSE: AssureService,
    private checkIsCompleteSE: CheckIsComponentTypeCompleteService,
    private fieldCCASE: FieldCCAService
  ) {}

  ngOnInit() {}

  ngAfterViewInit(): void {
    this.calculateVerticalLineHeight();
    this.subscribeToOpenEvent(this.subsections);
    this.subscribeToOpenEvent(this.ccas);
  }

  ngOnDestroy(): void {
    this.extraClass.intervalSubject.next('');
    this.extraClass.intervalSubject.complete();
  }

  /***
   * HANDLERS
   */
  successUpdateSectionHandler(res: iResultHttp) {
    this.DDIS.generalLoaderSE.removeFromLoaderAmount();
  }
  successDeleteSectionHandler(res: iResultHttp) {
    this.recall.emit(true);
    this.DDIS.generalLoaderSE.removeFromLoaderAmount();
  }
  successCreateSubsectionHandler(res: iResultHttp) {
    this.recall.emit(true);
    this.DDIS.generalLoaderSE.removeFromLoaderAmount();
  }
  successCreateCCAHandler(res: iResultHttp) {
    this.recall.emit(true);
    this.DDIS.generalLoaderSE.removeFromLoaderAmount();
  }
  successCreateFieldCCAHandler(res: iResultHttp) {
    this.recall.emit(true);
    this.DDIS.generalLoaderSE.removeFromLoaderAmount();
  }

  /***
   * FUNCTIONALITIES
   */

  calculateVerticalLineHeight() {
    const height = this.sectionContainer.nativeElement.offsetHeight;
    if (height < 134) {
      this.leftLineVertical.nativeElement.style.height = `${0}px`;
      return;
    }
    this.checkSubsectionsForLineHeight(height);
    this.checkCCAsForLineHeight(height);
  }

  checkSubsectionsForLineHeight(height: number) {
    const subsections: NodeListOf<HTMLElement> =
      this.sectionContainer.nativeElement.querySelectorAll('subsection-line');
    const lastSubsection: HTMLElement = subsections[subsections.length - 1];
    if (!lastSubsection) return;
    const lastSubsectionHeigth: number = lastSubsection.offsetHeight;

    if (lastSubsectionHeigth < 70) {
      if (height > 134) {
        this.leftLineVertical.nativeElement.style.height = `${
          height - 134 + 30
        }px`;
        return;
      }
    } else {
      if (height > 134) {
        const heightDifference = lastSubsectionHeigth - 67;
        this.leftLineVertical.nativeElement.style.height = `${
          height - 134 - heightDifference + 30
        }px`;
        return;
      }
    }
  }

  checkCCAsForLineHeight(height: number) {
    const ccas: NodeListOf<HTMLElement> =
      this.sectionContainer.nativeElement.querySelectorAll('cca-line');
    const lastCCA: HTMLElement = ccas[ccas.length - 1];
    if (!lastCCA) return;
    const lastCCAHeight: number = lastCCA.offsetHeight;
    if (lastCCAHeight < 70) {
      if (height > 134) {
        this.leftLineVertical.nativeElement.style.height = `${
          height - 134 + 28
        }px`;
        return;
      }
    } else {
      if (height > 134) {
        const heightDifference = lastCCAHeight - 67;
        this.leftLineVertical.nativeElement.style.height = `${
          height - 134 - heightDifference + 28
        }px`;
        return;
      }
    }
  }

  private subscribeToOpenEvent(components: QueryList<any>): void {
    const lastElement = this.section.children[this.section.children.length - 1];
    components.forEach((component, index) => {
      if (
        component instanceof SubsectionLineComponent &&
        component.subsection.id === lastElement.id
      )
        return;
      if (
        component instanceof CcaLineComponent &&
        component.cca.id === lastElement.id
      )
        return;
      if (component instanceof SubsectionLineComponent) {
        this.subscribeToOpenEvent(component.ccas);
      }
      component.open
        .pipe(takeUntil(this.extraClass.intervalSubject))
        .subscribe((event: any) => {
          timer(0, 10)
            .pipe(take(50))
            .subscribe(() => {
              this.calculateVerticalLineHeight();
            });
        });
    });
  }

  onChangeToUpdate(value: any, key: string) {
    const objectToPass = {
      id: this.section.id,
      [key]: value,
    };
    this.updateSection(objectToPass);
  }

  onClickCreateSubsection() {
    const objectToPass = {
      section: this.section.id,
      name: 'Nueva subsección',
    };
    this.createSubsection(objectToPass);
  }

  onclickCreateCCA() {
    const objectToPass = {
      section: this.section.id,
      name: 'Nueva CCA',
    };
    if (this.isComponent) {
      this.createFieldCCA(objectToPass);
      return;
    }
    this.createCCA(objectToPass);
  }

  assureDelete(element: string, id: number) {
    this.assureSE.assureDelete(element, () => this.deleteSection(id));
  }

  checkIsCompleteHandler(value: any) {
    this.checkIsCompleteSE.setElement(
      this.section.id,
      null !== value && undefined !== value && '' !== value && false !== value
    );
  }

  checkIfElementIsFull(value: any, key: string) {
    if (!value) {
      StaticUtilitiesService.showWarning('No puedes dejar este campo vacío');
      return;
    }
    this.onChangeToUpdate(value, key);
    this.checkIsCompleteHandler(value);
  }

  /***
   * API CALLS
   */

  updateSection(objectToPass: any) {
    this.DDIS.generalLoaderSE.addToLoaderAmount();
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.sectionSE.update(behaviorSubject, objectToPass);
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () => this.successUpdateSectionHandler(res),
          },
          {
            method: () => this.DDIS.generalLoaderSE.removeFromLoaderAmount(),
            error: true,
          },
        ]);
      });
  }

  deleteSection(id: number) {
    this.DDIS.generalLoaderSE.addToLoaderAmount();
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.sectionSE.delete(behaviorSubject, id);
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () => this.successDeleteSectionHandler(res),
          },
          {
            method: () => this.DDIS.generalLoaderSE.removeFromLoaderAmount(),
            error: true,
          },
        ]);
      });
  }

  createSubsection(objectToPass: any) {
    this.DDIS.generalLoaderSE.addToLoaderAmount();
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.subsectionSE.create(behaviorSubject, objectToPass);
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () => this.successCreateSubsectionHandler(res),
          },
          {
            method: () => this.DDIS.generalLoaderSE.removeFromLoaderAmount(),
            error: true,
          },
        ]);
      });
  }

  createCCA(objectToPass: any) {
    this.DDIS.generalLoaderSE.addToLoaderAmount();
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.CCASE.create(behaviorSubject, objectToPass);
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () => this.successCreateCCAHandler(res),
          },
          {
            method: () => this.DDIS.generalLoaderSE.removeFromLoaderAmount(),
            error: true,
          },
        ]);
      });
  }

  createFieldCCA(objectToPass: any) {
    this.DDIS.generalLoaderSE.addToLoaderAmount();
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.fieldCCASE.create(behaviorSubject, objectToPass);
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () => this.successCreateFieldCCAHandler(res),
          },
          {
            method: () => this.DDIS.generalLoaderSE.removeFromLoaderAmount(),
            error: true,
          },
        ]);
      });
  }
}
