import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { IconsHandlerService } from '../../../Services/Utils/IconsHandler.service';
import { StaticUtilitiesService, slideNavigationTypes } from '@quasar-dynamics/ngx-qd-design-system';

@Component({
  selector: 'Sidebar',
  templateUrl: './Sidebar.component.html',
  styleUrls: ['./Sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

  @Output() resize:EventEmitter<boolean> = new EventEmitter();

  isOpened = false;

  IconsHandlerService = IconsHandlerService;

  constructor(private staticUtilitiesSE:StaticUtilitiesService) { }

  ngOnInit() {
  }

  /***
   * FUNCTIONALITIES
   */


}
