<div class="popupGeneralContainer">
  <div class="header">
    <p>Adjuntar documento</p>
    <mat-icon (click)="closePopup(false)">close</mat-icon>
  </div>
  <div class="body" >
    <add-file style="width: 100%;" [acceptedFileTypesArray]="extraClass.acceptedFileTypesArray" [maxFileSizeMB]="extraClass.maxFileSizeMB" [filesArray]="extraClass.filesArray" (emitFiles)="getFiles($event)" (change)="checkIsComplete()" />
    <div class="formLine double ">
      <customInput [options]='extraClass.documentNameCustomInputOptions' [(ngModel)]="extraClass.ngModels.name" (change)="checkIsComplete()" ></customInput>
    </div>
    <div class="formLine double ">
      <mat-checkbox [(ngModel)]="extraClass.ngModels.annex" (change)="checkIsComplete()">Anexo</mat-checkbox>
    </div>
    <div class="formLine ">
      <customInput style="width: 100%;" [(ngModel)]="extraClass.ngModels.observations" [options]='extraClass.observationsCustomInputOptions' (change)="checkIsComplete()" ></customInput>
    </div>
  </div>

  <div class="footer">
    <custom-button [options]="extraClass.cancelButtonOptions" (click)="closePopup(false)" />
    <custom-button [options]="extraClass.saveButtonOptions" [class.disabled]="!extraClass.isCompleteObject" (click)="onSaveButtonClick()" />
  </div>
</div>
