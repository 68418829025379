import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  IOptionCustomTextArea,
  iOptionsCustomInput,
} from '@quasar-dynamics/ngx-qd-design-system';

@Component({
  selector: 'View-Observations-Popup',
  templateUrl: './View-Observations-Popup.component.html',
  styleUrls: ['./View-Observations-Popup.component.scss'],
})
export class ViewObservationsPopupComponent implements OnInit {
  observations: string | null = null;

  // customInputOptions
  observationsCustomInputOptions: IOptionCustomTextArea = {
    placeholder: 'Observaciones',
    cols: 30,
    rows: 10,
    disabled: true,
    label: 'Observaciones',
    classList: 'dark-textarea',
  };

  constructor(
    public dialogRef: MatDialogRef<ViewObservationsPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public modalData: any
  ) {}

  ngOnInit() {
    this.setModalData(this.modalData);
  }

  /**
   * FUNCTIONALITY
   */
  closePopup(returnValue?: any) {
    let p = { returnValue: returnValue };
    this.dialogRef.close(p);
  }

  setModalData(modalData: any) {
    if (modalData.observations) {
      this.observations = modalData.observations;
    }
  }
}
