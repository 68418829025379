<div class="iconContainer" #iconContainer>
  <ng-container *ngFor="let icon of icons">


    <span class="iconContainer--box"
      [ngClass]="{'disabled': icon.enableCondition !== undefined && icon.enableCondition(icons) === false}"
      (click)="$event.stopPropagation();icon.action(item)">

      <ng-container *ngIf="icon.matIconName">
        <mat-icon>{{icon.matIconName}}</mat-icon>
      </ng-container>

      <ng-container *ngIf="icon.image">
        <img src="{{icon.image}}" />
      </ng-container>

    </span>


  </ng-container>
</div>
