<div class="popupGeneralContainer">
  <div class="header">
    <p>Crear Cliente</p>
    <mat-icon (click)="closePopup(false)">close</mat-icon>
  </div>
  <div class="body">
    <div class="formLine">
      <customInput
        style="width: 100%"
        [options]="extraClass.clientNameCustomInputOptions"
        [(ngModel)]="extraClass.ngModels.name"
      />
    </div>
    <div class="formLine">
      <customInput
        style="width: 100%"
        [options]="extraClass.siglasNameCustomInputOptions"
        [(ngModel)]="extraClass.ngModels.acronym"
      />
    </div>
  </div>

  <div class="footer">
    <br />
    <custom-button
      [options]="extraClass.saveButtonOptions"
      (click)="onClickCreateClient()"
      [class.disabled]="
        !extraClass.ngModels.name || !extraClass.ngModels.acronym
      "
    />
  </div>
</div>
