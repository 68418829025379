import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MoveToPopupClass } from '../../Shared/Class/MoveToPopupClass';
import { SectionService } from '../../Services/Api/Section.service';
import {
  StaticUtilitiesService,
  iResultHttp,
} from '@quasar-dynamics/ngx-qd-design-system';
import { DefaultDependenciesInjectorService } from '../../Services/Utils/default-dependencies-injector.service';
import { filter, takeUntil } from 'rxjs';
import { SubsectionService } from '../../Services/Api/Subsection.service';

@Component({
  selector: 'Move-To-Popup',
  templateUrl: './Move-To-Popup.component.html',
  styleUrls: ['./Move-To-Popup.component.scss'],
})
export class MoveToPopupComponent implements OnInit {
  extraClass: MoveToPopupClass = new MoveToPopupClass();

  constructor(
    public dialogRef: MatDialogRef<MoveToPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public modalData: any,
    private sectionSE: SectionService,
    private DDIS: DefaultDependenciesInjectorService,
    private subsectionSE: SubsectionService
  ) {}

  ngOnInit() {
    if (this.modalData.isComponent) {
      this.getComponentSelectorByComponentType();
    } else {
      this.getSectionsSelectorByComponentType();
    }
  }
  /***
   * API CALLS HANDLER
   */
  successGetSectionsSelectorByComponentTypeHandler(res: iResultHttp) {
    const { data } = res;
    this.extraClass.sectionSelectorOptions.items = data;
    this.DDIS.generalLoaderSE.removeFromLoaderAmount();
  }
  successGetSubsectionSelectorHandler(res: iResultHttp) {
    const { data } = res;
    this.extraClass.subsectionSelectorOptions.items = data;
    this.DDIS.generalLoaderSE.removeFromLoaderAmount();
  }
  successGetComponentSelectorByComponentTypeHandler(res: iResultHttp) {
    const { data } = res;
    this.extraClass.sectionSelectorOptions.items = data;
    this.DDIS.generalLoaderSE.removeFromLoaderAmount();
  }

  /**
   * FUNCTIONALITY
   */
  closePopup(returnValue?: any) {
    let p = { returnValue: returnValue };
    this.dialogRef.close(p);
  }

  onSaveButtonClick() {
    this.closePopup(this.extraClass.ngModels);
  }

  /**
   * API CALLS
   */

  getSectionsSelectorByComponentType() {
    this.DDIS.generalLoaderSE.addToLoaderAmount();
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.sectionSE.getSelectorByComponentType(
      behaviorSubject,
      this.modalData?.componentTypeId
    );
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () =>
              this.successGetSectionsSelectorByComponentTypeHandler(res),
          },
          {
            method: () => this.DDIS.generalLoaderSE.removeFromLoaderAmount(),
            error: true,
          },
        ]);
      });
  }

  getComponentSelectorByComponentType() {
    this.DDIS.generalLoaderSE.addToLoaderAmount();
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.sectionSE.getComponentSelectorByComponentType(
      behaviorSubject,
      this.modalData?.componentTypeId
    );
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () =>
              this.successGetComponentSelectorByComponentTypeHandler(res),
          },
          {
            method: () => this.DDIS.generalLoaderSE.removeFromLoaderAmount(),
            error: true,
          },
        ]);
      });
  }

  getSubsectionSelector(sectionId: number | null) {
    if (!sectionId || this.modalData.type === 'subsection') return;
    this.DDIS.generalLoaderSE.addToLoaderAmount();
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.subsectionSE.getSelectorBySection(behaviorSubject, sectionId);
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () => this.successGetSubsectionSelectorHandler(res),
          },
          {
            method: () => this.DDIS.generalLoaderSE.removeFromLoaderAmount(),
            error: true,
          },
        ]);
      });
  }
}
