import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'table-item-box',
  templateUrl: './table-item-box.component.html',
  styleUrls: ['./table-item-box.component.scss']
})
export class TableItemBoxComponent implements OnInit {

  @Input() value: string | number = '';
  @Input() type: string = '';

  constructor() { }

  ngOnInit(): void {
  }

}
