import { IconsHandlerService } from '../../Services/Utils/IconsHandler.service';
import { iButtonOptions } from '../Interfaces/Utils/iButtonOptions';
import {
  IComponentTypeByIdToEdit,
  iComponentTypeByIdToEdit,
} from '../Interfaces/Utils/iComponentTypeByIdToEdit';

export class ComponentTypeTreeClass {
  IconsHandlerService = IconsHandlerService;

  lineHeight: number = 75;

  sections: iComponentTypeByIdToEdit['sections'] =
    IComponentTypeByIdToEdit.getEmptyObject().sections;

  addSectionButton: iButtonOptions = {
    class: 'secondary',
    icon: {
      icon: true,
      image: IconsHandlerService.getAddDarkBlueIcon(),
    },
    text: 'Añadir sección',
  };

  emptyAddCCALineButtonOptions: iButtonOptions = {
    class: 'quaternary',
    icon: {
      icon: true,
      image: IconsHandlerService.getAddCyanIcon(),
    },
    text: 'CCA',
  };

  openedElements: { type: 'subsection' | 'cca' | 'ccaType'; id: number }[] = [];
}
