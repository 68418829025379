import { Injectable } from '@angular/core';
import {
  PopupService,
  StaticUtilitiesService,
} from '@quasar-dynamics/ngx-qd-design-system';
import { takeUntil } from 'rxjs';
import { AssertCopyPopupComponent } from '../../Popups/Assert-Copy-Popup/Assert-Copy-Popup.component';
import { AssertDeletePopupComponent } from '../../Popups/Assert-Delete-Popup/Assert-Delete-Popup.component';

@Injectable({
  providedIn: 'root',
})
export class AssureService {
  constructor(private popupSE: PopupService) {}

  assureDelete(element: string, callback: Function) {
    const Subject = StaticUtilitiesService.createSubject();
    this.popupSE.openPopup(AssertDeletePopupComponent, { element });
    this.popupSE
      .returnData()
      .pipe(takeUntil(Subject))
      .subscribe((res) => {
        const returnValue = res?.returnValue;
        if (returnValue === undefined) return;
        if (returnValue) {
          callback();
        }
        Subject.next('');
        Subject.complete();
      });
  }
  assureCopy(element: string, callback: Function) {
    const Subject = StaticUtilitiesService.createSubject();
    this.popupSE.openPopup(AssertCopyPopupComponent, { element });
    this.popupSE
      .returnData()
      .pipe(takeUntil(Subject))
      .subscribe((res) => {
        const returnValue = res?.returnValue;
        if (returnValue === undefined) return;
        if (returnValue) {
          callback();
        }
        Subject.next('');
        Subject.complete();
      });
  }
}
