import { Component, Input, OnInit } from '@angular/core';
import { Icons } from '../main-table/main-table.component';

@Component({
  selector: 'icon-container',
  templateUrl: './icon-container.component.html',
  styleUrls: ['./icon-container.component.scss'],
})
export class IconContainerComponent implements OnInit {
  @Input({ required: true }) item: any = null;

  @Input({ required: true }) icons: Icons['icons'] = [];

  constructor() {}

  ngOnInit() {}
}
