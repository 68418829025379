<!-- <selector style="width: 100%;" (input)="getSelectorText($event)" (keyup.enter)="getSelector($event)"
    (change)="getChangeSelector($event)" [(ngModel)]="model" [options]="selectorOptions"></selector> -->
    <div class="labelWrapper" #labelWrapper [ngClass]="selectorOptions.classList ?? '' " *ngIf="selectorOptions?.label">
        <label>{{selectorOptions.label}}
          <sup *ngIf="selectorOptions.required">*</sup>
        </label>
        <ng-template [ngTemplateOutlet]="select"></ng-template>
      </div>
      
      <ng-template [ngTemplateOutlet]="select" *ngIf="!selectorOptions?.label"></ng-template>
      
<ng-template #select>


    <div class="selector" [ngClass]="selectorOptions.classList ?? '' ">

        <ng-select (input)="getSelectorText($event)" (keyup.enter)="getSelector($event)"
            (change)="getChangeSelector($event)" [items]="selectorOptions.items" [ngModel]="model"
            (ngModelChange)="onNgModelChange($event)" (change)="sendOnChange()" (add)="sendAdd($event)"
            (remove)="sendRemove($event)" (close)="sendClose()" (clear)="sendClear()"
            [name]="selectorOptions.nameForm ?? '' " [placeholder]="selectorOptions.placeholder ?? '' "
            [searchable]="selectorOptions.search ?? false " [searchFn]="getNullValueDefault(selectorOptions.searchFn)"
            [multiple]="checkOptionsKey(selectorOptions.multiple, 'isMultiple', false) "
            [maxSelectedItems]="checkOptionsKey(selectorOptions.multiple, 'maxSelectedItems', undefined) "
            [hideSelected]="checkOptionsKey(selectorOptions.multiple, 'hideSelected', false)"
            [clearable]="selectorOptions.clearable ?? true "
            [bindLabel]="getNullValueDefault(selectorOptions.bindLabel) "
            [bindValue]="getNullValueDefault(selectorOptions.bindValue) " [appendTo]="returnTypeAny(checkAppendTo())"
            [closeOnSelect]="selectorOptions.closeOnSelect ?? true"
            [groupBy]="checkOptionsKey(selectorOptions.groups, 'groupBy', null)"
            [selectableGroup]="checkOptionsKey(selectorOptions.groups, 'selectableGroup', false)"
            [selectableGroupAsModel]="checkOptionsKey(selectorOptions.groups, 'selectableGroupAsModel', true)"
            [groupValue]="checkOptionsKey(selectorOptions.groups, 'groupValue', null)" [disabled]="_disabled"
            [addTag]="checkOptionsKey(selectorOptions.tags, 'addTag', false)"
            [addTagText]="checkOptionsKey(selectorOptions.tags, 'addTagText', getSelectorAddTag())"
            [clearAllText]="selectorOptions.clearAllText ?? 'Borrar todos'"
            [readonly]="selectorOptions.readonly ?? false">
            <ng-template ng-label-tmp let-item="item">
                <span class="ng-value-label" *ngIf="!selectorOptions.bindLabel">{{item}}</span>
                <span class="ng-value-label"
                    *ngIf="selectorOptions.bindLabel">{{item[selectorOptions.bindLabel]}}</span>
            </ng-template>
            <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index" let-searchTerm="searchTerm">
                <div class="spaceBetween" [ngClass]="{reverse: reverse}">
                    <p>
                        {{selectorOptions.bindLabel ? item[selectorOptions.bindLabel] : item}}
                    </p>
                    <span
                        (click)="$event.stopPropagation(); deleteSelectorFunction(selectorOptions.bindValue ? item[selectorOptions.bindValue] : item)">
                        <mat-icon>close</mat-icon> </span>
                </div>
            </ng-template>
        </ng-select>
    </div>
</ng-template>