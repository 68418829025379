import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { StaticUtilitiesService } from '@quasar-dynamics/ngx-qd-design-system';
import { iBase64File } from '../../Interfaces/Utils/iBase64File';
import { IconsHandlerService } from '../../../Services/Utils/IconsHandler.service';


@Component({
  selector: 'add-file',
  templateUrl: './Add-file.component.html',
  styleUrls: ['./Add-file.component.scss'],
})
export class AddFileComponent implements OnInit {
  private _maxFileSizeMB: number = 10;

  @Output() emitFiles:EventEmitter<iBase64File[]> = new EventEmitter<iBase64File[]>();
  @Input() acceptedFileTypesArray: string[] = [];
  @Input() multiple: boolean = false;
  @Input() filesArray: iBase64File[] = [];
  @Input() get maxFileSizeMB() {
    return this._maxFileSizeMB;
  }
  set maxFileSizeMB(value) {
    this._maxFileSizeMB = value;
    this.maxFileSize = value * 1024 * 1024;
  }

  IconsHandlerService = IconsHandlerService;

  file: any = null;

  isDragging: boolean = false;



  maxFileSize: number = 10 * 1024 * 1024;

  constructor() {}

  ngOnInit() {}

  dragEnter() {
    this.isDragging = true;
  }

  dragLeave() {
    this.isDragging = false;
  }

  updateFile(fileArray: iBase64File[]) {
    this.isDragging = false;
    this.filesArray = [];
    fileArray.forEach((file) => {
      if (!this.acceptedFileTypesArray.includes(file.file.type)) {
        StaticUtilitiesService.showWarning(
          `El archivo ${
            file.file.name
          } no es un tipo de archivo permitido. El archivo debe ser de tipo ${this.acceptedFileTypesArray.join(
            ', '
          )}`
        );
        return;
      }
      if (file.file.size > this.maxFileSize) {
        StaticUtilitiesService.showWarning(
          `El archivo es demasiado grande, el máximo permitido es de ${this.maxFileSizeMB}MB`
        );
        return;
      }
      this.filesArray.push(file);
    });
    this.emitFiles.emit(this.filesArray);
  }
}


