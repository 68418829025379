import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CreateEditOfferClass } from '../../Class/CreateEditOfferClass';
import { iCreateOfferNgModels } from '../../Interfaces/Utils/iCreateOfferNgModels';
import { DefaultDependenciesInjectorService } from '../../../Services/Utils/default-dependencies-injector.service';
import { AddClientPopupComponent } from '../../../Popups/Add-Client-Popup/Add-Client-Popup.component';
import { filter, Subject, takeUntil } from 'rxjs';
import {
  iResultHttp,
  StaticUtilitiesService,
} from '@quasar-dynamics/ngx-qd-design-system';
import { ClientService } from '../../../Services/Api/Client.service';

@Component({
  selector: 'create-edit-offer',
  templateUrl: './create-edit-offer.component.html',
  styleUrls: ['./create-edit-offer.component.scss'],
})
export class CreateEditOfferComponent implements OnInit {
  extraClass: CreateEditOfferClass = new CreateEditOfferClass();

  @Input({ required: true }) createOfferNgModels!: iCreateOfferNgModels;
  @Output() onChange: EventEmitter<iCreateOfferNgModels> = new EventEmitter();
  constructor(
    private DDIS: DefaultDependenciesInjectorService,
    private clientSE: ClientService
  ) {}

  ngOnInit() {
    this.getClientSelector();
  }

  /**
   * HANDLERS
   */

  successGetClientSelectorHandler(res: iResultHttp) {
    const { data } = res;
    this.extraClass.clientSelectorOptions.items = data;
    this.DDIS.generalLoaderSE.removeFromLoaderAmount();
  }

  openClientPopup() {
    const subject = new Subject();
    this.DDIS.popupSE.openPopup(AddClientPopupComponent);
    this.DDIS.popupSE
      .returnData()
      .pipe(takeUntil(subject))
      .subscribe((res) => {
        if (res === undefined) return;
        if (!res.returnValue) {
          subject.next(true);
          subject.complete();
        }
        this.getClientSelector();
        subject.next(true);
        subject.complete();
      });
  }

  change() {
    this.onChange.emit(this.createOfferNgModels);
  }

  /**
   * API CALLS
   */

  getClientSelector() {
    this.DDIS.generalLoaderSE.addToLoaderAmount();
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.clientSE.getSelector(behaviorSubject);
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () => this.successGetClientSelectorHandler(res),
          },
          {
            method: () => this.DDIS.generalLoaderSE.removeFromLoaderAmount(),
            error: true,
          },
        ]);
      });
  }
}
