import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SelectedDataTableBridgeService {
  private _selectedData: Array<any> = [];
  private _haveSelected: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);


  constructor() {}

  haveSelected(){
    return this._haveSelected;
  }

  getSelectedData() {
    return this._selectedData;
  }

  setSelectedData(_selectedData: any, allSelected?: boolean) {
    if (_selectedData instanceof Array) {
      for (const element of _selectedData) {
        const existIndex = this._selectedData.findIndex(
          (item) => item.id === element.id
        );

        if (allSelected) {
          if (existIndex === -1) {
            this._selectedData.push(element);
          }
        } else {
          this._selectedData.splice(existIndex, 1);
        }
      }

      this._selectedData = this._selectedData.filter(
        (element, index, self) =>
          self.findIndex((item) => item.id === element.id) === index
      );
    } else {
      const indexToRemove = this._selectedData.findIndex(
        (item) => item.id === _selectedData.id
      );

      if (indexToRemove !== -1) {
        this._selectedData.splice(indexToRemove, 1);
      } else {
        this._selectedData.push(_selectedData);
      }
    }

    this._haveSelected.next(this._selectedData.length > 0);
  }

  clearSelectedData() {
    this._selectedData = [];
  }
}
