import { Component, Input, OnInit } from '@angular/core';
import { iDateTable } from '../../main-table.component';

@Component({
  selector: 'table-item-chip',
  templateUrl: './table-item-chip.component.html',
  styleUrls: ['./table-item-chip.component.scss']
})
export class TableItemChipComponent implements OnInit {

  @Input() value: string | number = '';
  @Input() type: string = '';
  @Input() className: string = '';
  @Input() date: iDateTable | null = null;
  @Input() border: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

}
