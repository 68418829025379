import { iOptionsCustomInput } from '@quasar-dynamics/ngx-qd-design-system';
import { IconsHandlerService } from '../../Services/Utils/IconsHandler.service';
import { iButtonOptions } from '../Interfaces/Utils/iButtonOptions';

export class AddClientPopupClass {
  ngModels: { name: string | null; acronym: string | null } = {
    acronym: null,
    name: null,
  };

  canContinue: boolean = false;

  saveButtonOptions: iButtonOptions = {
    class: 'primary',
    text: 'Guardar',
    icon: {
      icon: true,
      image: IconsHandlerService.getTickIcon(),
    },
  };

  //   Custom input options
  clientNameCustomInputOptions: iOptionsCustomInput = {
    placeholder: 'Nombre del cliente',
    type: 'text',
    label: 'Nombre cliente',
    required: true,
    classList: 'dark-input',
  };
  siglasNameCustomInputOptions: iOptionsCustomInput = {
    placeholder: 'Siglas',
    type: 'text',
    label: 'Siglas',
    required: true,
    classList: 'dark-input',
  };
}
