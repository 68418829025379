import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

export interface iOptionMenu {
  label: string;
  action: string;
  icon?: string;
}

@Component({
  selector: 'options-menu',
  templateUrl: './options-menu.component.html',
  styleUrls: ['./options-menu.component.scss']
})
export class OptionsMenuComponent implements OnInit {

  @Input() options: iOptionMenu[] = [];

  @Output() optionSelected:EventEmitter<string> = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {
  }

  sendClick(action: string){
    this.optionSelected.emit(action);
  }
}
