<ng-container *ngIf="options.length>0">
  <button mat-icon-button (click)="$event.stopPropagation()" [matMenuTriggerFor]="menu" >
    <mat-icon>more_vert</mat-icon>
  </button>
  <mat-menu #menu="matMenu" >
    <ng-container *ngFor="let option of options">
      <button mat-menu-item (click)="sendClick(option.action)">
        <ng-container *ngIf="option.icon">
          <mat-icon>{{option.icon}}</mat-icon>
        </ng-container>
        <span>{{option.label}}</span>
      </button>
    </ng-container>
  </mat-menu>
</ng-container>