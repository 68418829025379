import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CheckIsComponentTypeCompleteService {
  private elementsArray: { id: number | string; isComplete: boolean }[] = [];

  private isCompleteBehaviorSubject: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  constructor() {}

  public setElement(id: number | string, isComplete: boolean) {
    const index = this.elementsArray.findIndex((element) => element.id === id);
    if (index !== -1) {
      this.elementsArray[index].isComplete = isComplete;
    } else {
      this.elementsArray.push({ id, isComplete });
    }
    this.setIsCompleteBehaviorSubject();
  }

  private setIsCompleteBehaviorSubject() {
    const isComplete = this.elementsArray.every(
      (element) => element.isComplete
    );
    this.isCompleteBehaviorSubject.next(isComplete);
  }

  public getIsCompleteBehaviorSubject(): BehaviorSubject<boolean> {
    return this.isCompleteBehaviorSubject;
  }
}
