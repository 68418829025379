import { iOptionsCustomInput } from '@quasar-dynamics/ngx-qd-design-system';
import { IconsHandlerService } from '../../Services/Utils/IconsHandler.service';
import { Icons } from '../Components/main-table/main-table.component';
import {
  CCA,
  IComponentTypeByIdToEdit,
} from '../Interfaces/Utils/iComponentTypeByIdToEdit';

export class CcaLineClass {
  constructor(
    private assureDelete: (element: string, id: number) => void,
    private assureCopy: (element: string, id: number) => void
  ) {}

  _isOpen: boolean | undefined = false;
  _cca: CCA = IComponentTypeByIdToEdit.getEmptyCCA();

  ngModels = {
    verificationType: null,
  };

  showInfoContainer: boolean = false;

  radioButtons: string[] = ['INSP', 'END', 'ED'];

  IconsHandlerService = IconsHandlerService;

  // Custom input options
  ccaNameCustomInputOptions: iOptionsCustomInput = {
    placeholder: 'Nombre CCA',
    type: 'text',
    classList: 'dark-input',
  };
  planDeMuestreoCustomInputOptions: iOptionsCustomInput = {
    placeholder: 'Plan de muestreo',
    type: 'text',
    label: 'Plan de muestreo',
    classList: 'dark-input',
  };
  procedimientoDeEnsayoCustomInputOptions: iOptionsCustomInput = {
    placeholder: 'Procedimiento de ensayo',
    type: 'text',
    label: 'Procedimiento de ensayo',
    classList: 'dark-input',
  };
  criterioAceptacionCustomInputOptions: iOptionsCustomInput = {
    placeholder: 'Criterio de aceptación',
    type: 'text',
    label: 'Criterio de aceptación',
    classList: 'dark-input',
  };
  documentacionCACustomInputOptions: iOptionsCustomInput = {
    placeholder: 'Documentación CA',
    type: 'text',
    label: 'Documentación CA',
    classList: 'dark-input',
  };
  obsLabCustomInputOptions: iOptionsCustomInput = {
    placeholder: 'Obs. / Lab.',
    type: 'text',
    label: 'Obs. / Lab.',
    classList: 'dark-input',
  };
  observacionesCustomInputOptions: iOptionsCustomInput = {
    placeholder: 'Observaciones',
    type: 'text',
    label: 'Observaciones',
    classList: 'dark-input',
  };
  acceptanceMethod: iOptionsCustomInput = {
    placeholder: '',
    type: 'text',
    label: 'Método de aceptación',
    characterCounter: {
      maxCharacterLength: 1,
    },
    classList: 'dark-input',
  };
  quantityAssayed: iOptionsCustomInput = {
    placeholder: '',
    type: 'number',
    label: 'Cantidad ensayada',
    classList: 'dark-input',
  };

  iconsSection: Icons['icons'] = [
    {
      action: (item) => this.assureCopy(`el CCA ${item.name}`, item.id),
      image: IconsHandlerService.getCopyBluePaleIcon(),
    },
    {
      action: (item) => this.assureDelete(`el CCA ${item.name}`, item.id),
      image: IconsHandlerService.getDeleteBluePaleIcon(),
    },
  ];
}
