import { Injectable } from '@angular/core';
import { iArchives } from '../../Shared/Interfaces/Utils/iComponentById';

@Injectable({
  providedIn: 'root',
})
export class StaticDataHandlerService {
  private static documents: iArchives[] = [
    {
      name: 'Documento 1',
      isAnnex: false,
      sequence: 1,
      url: 'https://www.google.com',
      id: 1,
      observations: 'NN',
    },
    {
      name: 'Anexo 1',
      isAnnex: true,
      id: 2,
      observations: null,
      sequence: 1,
      url: 'https://www.google.com',
    },
    {
      name: 'Documento 2',
      isAnnex: false,
      id: 3,
      observations: null,
      sequence: 1,
      url: 'https://www.google.com',
    },
    {
      name: 'Anexo 2',
      isAnnex: true,
      id: 4,
      observations: 'EE',
      sequence: 1,
      url: 'https://www.google.com',
    },
  ];

  private static colorsForCategories: string[] = [
    '--categoryColor-yellow',
    '--categoryColor-blue-sky',
    '--categoryColor-purple',
    '--categoryColor-blue-pale',
    '--categoryColor-green',
    '--categoryColor-saffronMango',
    '--categoryColor-greenishBeige',
    '--categoryColor-pictonBlue',
    '--categoryColor-shockingOrange',
    '--categoryColor-warmBlue',
    '--categoryColor-greyCloud',
    '--categoryColor-lightSeaGreen',
    '--categoryColor-raspberryRose',
    '--categoryColor-springLeaves',
    '--categoryColor-darkSeaGreen',
    '--categoryColor-pastelViolet',
    '--categoryColor-hawaiianTan',
  ];

  private static filtersLibrary: { [key: string]: string } = {
    Cliente: 'orderByClient',
    'Nº oferta': 'orderByOffer',
    Componente: 'orderByComponent',
    Partida: 'orderByItem',
  };

  constructor() {}

  public static getDocuments() {
    return this.documents;
  }
  public static getColorsForCategories() {
    return this.colorsForCategories;
  }
  public static getFiltersLibrary() {
    return this.filtersLibrary;
  }
}
