<div class="subsection-line" [ngClass]="{'active': extraClass.showInside}">

  <div class="line-box subsection" [ngClass]="{'active': extraClass.showInside}" (click)="toggleShowInside()">
    <div class="componentsFlex">
      <ng-content select=".handler">

      </ng-content>
      <img (click)="$event.stopImmediatePropagation();sendOpenPopup(subsection)" [src]="extraClass.IconsHandlerService.getSwapIcon()" alt="swap">
      <customInput [(ngModel)]="subsection.name" [options]='extraClass.subsectionNameCustomInputOptions' (change)="checkIfElementIsFull(subsection.name,'name')"  (click)="$event.stopImmediatePropagation()"></customInput>
      <custom-button [options]="extraClass.ccaButtonOptions" (click)="$event.stopImmediatePropagation();onclickCreateCCA()" />
    </div>
    <div class="componentsFlex">
      <icon-container [item]="subsection" [icons]="extraClass.iconsSection" />
      <img class="turnable" [src]="extraClass.IconsHandlerService.getOpenIcon()" alt="open">
    </div>
  </div>

  <div id="wrapper" [ngClass]="{'active': extraClass.showInside}">

    <div class="openedInfo">
      <ng-content>

      </ng-content>

    </div>
  </div>


</div>