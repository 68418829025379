import { iOptionsSelector } from "@quasar-dynamics/ngx-qd-design-system"
import { IconsHandlerService } from "../../Services/Utils/IconsHandler.service"
import { iButtonOptions } from "../Interfaces/Utils/iButtonOptions"

export class MoveToPopupClass {

    IconsHandlerService =IconsHandlerService

    ngModels = {
        section:null,
        subsection:null
    }

    cancelButtonOptions:iButtonOptions = {
        class: "tertiary",
        icon:{
            icon:true,
            image: this.IconsHandlerService.getCancelSkyBlueIcon()
        },
        text: "Cancelar"
    }
    saveButtonOptions:iButtonOptions = {
        class: "primary",
        icon:{
            icon:true,
            image: this.IconsHandlerService.getTickIcon()
        },
        text: "Guardar"
    }

    // selectorOptions
    sectionSelectorOptions:iOptionsSelector = {
        label: "Selecciona una sección",
        placeholder: "Selecciona una sección",
        items: [],
        append:true,
        bindLabel: "name",
        bindValue: "id",
        classList:'dark-selector',
        clearable:false,
        search:true,
        notFoundText:"No se encontraron secciones"

    }
    subsectionSelectorOptions:iOptionsSelector = {
        label: "Selecciona una subsección",
        placeholder: "Selecciona una subsección",
        items: [],
        append:true,
        bindLabel: "name",
        bindValue: "id",
        classList:'dark-selector',
        search:true,

    }

}
