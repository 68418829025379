import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AddClientPopupClass } from '../../Shared/Class/AddClientPopupClass';
import { ClientService } from '../../Services/Api/Client.service';
import { DefaultDependenciesInjectorService } from '../../Services/Utils/default-dependencies-injector.service';
import {
  iResultHttp,
  StaticUtilitiesService,
} from '@quasar-dynamics/ngx-qd-design-system';
import { filter, takeUntil } from 'rxjs';

@Component({
  selector: 'Add-Client-Popup',
  templateUrl: './Add-Client-Popup.component.html',
  styleUrls: ['./Add-Client-Popup.component.scss'],
})
export class AddClientPopupComponent implements OnInit {
  extraClass: AddClientPopupClass = new AddClientPopupClass();

  constructor(
    public dialogRef: MatDialogRef<AddClientPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public modalData: any,
    private clientSE: ClientService,
    private DDIS: DefaultDependenciesInjectorService
  ) {}

  ngOnInit() {}

  /**
   * HANDLERS
   */

  successCreateClientHandler(res: iResultHttp) {
    const { data } = res;
    this.closePopup(true);
    this.DDIS.generalLoaderSE.removeFromLoaderAmount();
  }

  /**
   * FUNCTIONALITY
   */
  closePopup(returnValue?: any) {
    let p = { returnValue: returnValue };
    this.dialogRef.close(p);
  }

  onClickCreateClient() {
    this.createClient(this.extraClass.ngModels);
  }

  /**
   * API CALLS
   */

  createClient(objectToPass: any) {
    this.DDIS.generalLoaderSE.addToLoaderAmount();
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.clientSE.create(behaviorSubject, objectToPass);
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () => this.successCreateClientHandler(res),
          },
          {
            method: () => this.DDIS.generalLoaderSE.removeFromLoaderAmount(),
            error: true,
          },
        ]);
      });
  }
}
