<p
  class="tableItemChip {{className}}"
  [ngClass]="{
    'tableItemChip--small': size === 'sm',
    'tableItemChip--big': size === 'lg',
    'tableItemChip--bold': bold
  }"
  [ngStyle]="border ? {'border': 'var(' + type + ') 1px solid', 'padding': '4px 1.5rem','color':'var(' + type + ')' } : {'background-color': 'var(' + type + ')'}"
  >
<ng-container *ngIf="date?.date">
  {{value | date: date?.pipeTemplate}}
</ng-container>
<ng-container *ngIf="!date?.date">
  {{value}}
</ng-container>

  
</p>
