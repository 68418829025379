import { Component, Input, OnInit } from '@angular/core';
import { iDateTable } from '../main-table/main-table.component';

@Component({
  selector: 'Chip',
  templateUrl: './chip.component.html',
  styleUrls: ['./chip.component.scss']
})
export class ChipComponent implements OnInit {

  @Input() value: string | number = '';
  @Input() type: string = '';
  @Input() className: string = '';
  @Input() bold: boolean = false;
  @Input() border: boolean = false;
  @Input() size: 'sm' | 'md' | 'lg' = 'md';
  @Input() date: iDateTable | null = null;


  constructor() { }

  ngOnInit(): void {
  }

}
