<div id="form">
  <div class="formLine double">
    <customInput
      [options]="extraClass.offerNumberCustomInputOptions"
      [(ngModel)]="createOfferNgModels.offerNumber"
      (change)="change()"
    ></customInput>
    <customInput
      [options]="extraClass.offerRequestCustomInputOptions"
      [(ngModel)]="createOfferNgModels.po"
      (change)="change()"
    ></customInput>
  </div>

  <div class="formLine double">
    <selector
      [options]="extraClass.clientSelectorOptions"
      [(ngModel)]="createOfferNgModels.client"
      (change)="change()"
    ></selector>
    <custom-button
      [options]="extraClass.addClientButtonOptions"
      (click)="openClientPopup()"
    ></custom-button>
  </div>

  <div class="formLine">
    <customTextArea
      (change)="change()"
      style="width: 100%"
      [options]="extraClass.offerDescriptionCustomTextAreaOptions"
      [(ngModel)]="createOfferNgModels.description"
    ></customTextArea>
  </div>
  <div class="formLine">
    <customTextArea
      (change)="change()"
      style="width: 100%"
      [options]="extraClass.offerObservationsCustomTextAreaOptions"
      [(ngModel)]="createOfferNgModels.observations"
    ></customTextArea>
  </div>
</div>
