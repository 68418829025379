export interface iComponentTypeByIdToEdit {
  component: ComponentToEdit;
  sections: Section[];
  archives: Archives[];
}
export interface ComponentToEdit {
  [key: string]: any;
  id: number;
  name: string;
  categoryId: number;
  categoryName: string;
  categoryColor: string;
  whoPreparesInitialism: string;
  preparationDate: Date; // could be Date if parsed
  whoReviewsInitialism: string;
  reviewDate: Date; // could be Date if parsed
  whoApprovesInitialism: string;
  approvalDate: Date; // could be Date if parsed
  template: boolean;
}
// Define the CCA interface (Child Check Activities)
export interface CCA {
  type: 'cca' | 'ccaType';
  id: number;
  name: string;
  isCC: boolean;
  sequence: number;
  verificationType: string;
  acceptanceMethod: number;
  samplingPlan: string;
  testProcedure: string;
  acceptanceRequirements: string;
  documentationCA: string;
  obsLab: string;
  observations: string;
  quantityAssayed?: number | null;
  isOpen?: boolean;
}
// Define the Section or Subsection interface
export interface Section {
  [key: string]: any;
  type: 'section' | 'subsection';
  id: number;
  name: string;
  sequence: number;
  isOpen?: boolean;
  children: (CCA | Section)[]; // Array containing either CCA or another Section/Subsection
}
// Define the Document interface
interface Archives {
  id: number;
  name: string;
  url: string;
  isAnnex: boolean;
  observations: string | null;
  sequence: number;
}

export class IComponentTypeByIdToEdit {
  static getEmptyObject(): iComponentTypeByIdToEdit {
    return {
      component: {
        id: 0,
        name: '',
        categoryId: 0,
        categoryName: '',
        categoryColor: '',
        whoPreparesInitialism: '',
        preparationDate: new Date(),
        whoReviewsInitialism: '',
        reviewDate: new Date(),
        whoApprovesInitialism: '',
        approvalDate: new Date(),
        template: false,
      },
      sections: [],
      archives: [
        {
          id: 0,
          name: '',
          url: '',
          isAnnex: false,
          observations: null,
          sequence: 0,
        },
      ],
    };
  }
  static getEmptyCCA(): CCA {
    return {
      acceptanceMethod: 0,
      id: 0,
      name: '',
      sequence: 0,
      type: 'cca',
      verificationType: '',
      isCC: false,
      samplingPlan: '',
      testProcedure: '',
      acceptanceRequirements: '',
      documentationCA: '',
      obsLab: '',
      observations: '',
    };
  }
}
