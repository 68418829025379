import { IconsHandlerService } from '../../Services/Utils/IconsHandler.service';
import { iButtonOptions } from '../Interfaces/Utils/iButtonOptions';

export class AssertCopyPopupClass {
  IconsHandlerService = IconsHandlerService;

  // ButtonOptions
  cancelButtonOptions: iButtonOptions = {
    class: 'tertiary',
    icon: {
      icon: true,
      image: this.IconsHandlerService.getCancelSkyBlueIcon(),
    },
    text: 'Cancelar',
  };
  deleteButtonOptions: iButtonOptions = {
    class: 'primary',
    icon: {
      icon: true,
      image: this.IconsHandlerService.getCopyBluePaleIcon(),
    },
    text: 'Copiar',
  };
}
