import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IconsHandlerService } from '../../../Services/Utils/IconsHandler.service';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import {
  StaticUtilitiesService,
  iResultHttp,
} from '@quasar-dynamics/ngx-qd-design-system';
import { iTableTicketOptions } from '../../Interfaces/Utils/iTableTicketOptions';
import { iDocumentIcon } from '../../Interfaces/Utils/iDocumentIcon';
import { DefaultDependenciesInjectorService } from '../../../Services/Utils/default-dependencies-injector.service';
import { filter, takeUntil } from 'rxjs';
import { iArchives } from '../../Interfaces/Utils/iComponentById';
import { ReorderService } from '../../../Services/Api/Reorder.service';
import { ArchiveService } from '../../../Services/Api/Archive.service';
import { AssureService } from '../../../Services/Utils/Assure.service';

@Component({
  selector: 'documents-table',
  templateUrl: './documents-table.component.html',
  styleUrls: ['./documents-table.component.scss'],
})
export class DocumentsTableComponent implements OnInit {
  @Output() onAddDocument: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() onEditDocument: EventEmitter<iArchives> =
    new EventEmitter<iArchives>();
  @Output() recallDocuments: EventEmitter<boolean> =
    new EventEmitter<boolean>();

  @Input({ required: true }) documents: iArchives[] = [];

  @Input() icons: iDocumentIcon[] = [
    {
      image: IconsHandlerService.getEditBluePaleIcon(),
      action: (row: iArchives) => {
        this.onEditDocumentClick(row);
      },
    },
    {
      image: IconsHandlerService.getDeleteBluePaleIcon(),
      action: (row: iArchives) => {
        this.onClickDelete(row);
      },
    },
  ];

  @Input() isView: boolean = false;

  tableTickOptions: iTableTicketOptions = {
    ticked: false,
    else: '-',
  };
  IconsHandlerService = IconsHandlerService;

  constructor(
    private DDIS: DefaultDependenciesInjectorService,
    private reorderSE: ReorderService,
    private archiveSE: ArchiveService,
    private assureSE: AssureService
  ) {}

  ngOnInit() {}

  /***
   * API CALLS HANDLERS
   */
  successReorderItemsHandler(res: iResultHttp) {
    this.recallDocuments.emit(true);
    this.DDIS.generalLoaderSE.removeFromLoaderAmount();
  }
  successDeleteDocumentHandler(res: iResultHttp) {
    this.recallDocuments.emit(true);
    this.DDIS.generalLoaderSE.removeFromLoaderAmount();
  }

  /**
   * FUNCTIONALITIES
   */

  onDocumentClick(document: iArchives) {
    window.open(document.url, '_blank');
  }

  onAddDocumentClick() {
    this.onAddDocument.emit(true);
  }

  onEditDocumentClick(document: iArchives) {
    this.onEditDocument.emit(document);
  }

  onListDrop(event: CdkDragDrop<any>) {
    StaticUtilitiesService.moveItemInArray(
      this.documents,
      event.previousIndex,
      event.currentIndex
    );
    const objectToPass = this.documents.map((document, index) => {
      return {
        id: document.id,
        sequence: index + 1,
        type: 'archive',
      };
    });
    this.reorderItems(objectToPass);
  }

  onClickDelete(row: any) {
    this.assureSE.assureDelete('el documento', () =>
      this.deleteDocument(row.id)
    );
  }

  reorderItems(objectToPass: any) {
    this.DDIS.generalLoaderSE.addToLoaderAmount();
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.reorderSE.update(behaviorSubject, objectToPass);
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () => this.successReorderItemsHandler(res),
          },
          {
            method: () => this.DDIS.generalLoaderSE.removeFromLoaderAmount(),
            error: true,
          },
        ]);
      });
  }

  deleteDocument(id: number) {
    this.DDIS.generalLoaderSE.addToLoaderAmount();
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.archiveSE.delete(behaviorSubject, id);
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () => this.successDeleteDocumentHandler(res),
          },
          {
            method: () => this.DDIS.generalLoaderSE.removeFromLoaderAmount(),
            error: true,
          },
        ]);
      });
  }
}
