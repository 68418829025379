<div class="tableContainer">
  <ng-container *ngIf="dataSource.length === 0">
    <p class="noItems">{{ noItemsMessage }}</p>
  </ng-container>

  <ng-container *ngIf="dataSource.length > 0">
    <table class="mainTable" [class.mainTable--slim]="slim">
      <thead class="thead">
        <tr class="tr" [ngStyle]="{ 'grid-template-columns': tableColumns }">
          <ng-container *ngIf="selectMultiple">
            <th>
              <mat-checkbox
                [checked]="allselected"
                (change)="handlerClickSelectAll($event.checked)"
              ></mat-checkbox>
            </th>
          </ng-container>
          <ng-container
            *ngFor="
              let headerItem of headerData;
              index as index;
              trackBy: trackByHeader
            "
          >
            <th
              class="th"
              [ngStyle]="{
                justifyContent: getAlignment(headerItem),
                minWidth: headerItem.width || '250px',
                maxWidth: headerItem.width || '250px'
              }"
              [class]="headerItem.variant + 'Header'"
            >
              @if(headerItem?.sort?.sort){ @if(headerItem?.sort?.sortedByMe){
              <mat-icon (click)="sendSortBy(false, headerItem)"
                >arrow_drop_up</mat-icon
              >
              } @else {
              <mat-icon (click)="sendSortBy(true, headerItem)"
                >arrow_drop_down</mat-icon
              >
              } }
              {{ headerItem.display }}
            </th>
          </ng-container>
        </tr>
      </thead>
      <tbody class="tbody">
        <ng-container
          *ngFor="let item of dataSource; index as i; trackBy: trackByLines"
        >
          @if(item.type === 'section' || item.type === 'subsection'){
          @if(item.type === 'section'){
          <tr class="custom section">
            <p>
              {{ item.name ?? "-" }}
            </p>
          </tr>
          } @else if(item.type === 'subsection'){
          <tr class="custom subsection">
            <p>
              {{ item.name ?? "-" }}
            </p>
          </tr>
          } } @else{

          <tr
            [class]="
              'tr' +
              (item?.isCC === null || item?.isCC == false ? ' notCC' : '')
            "
            (click)="handleClickRow(item)"
            [ngStyle]="{ 'grid-template-columns': tableColumns }"
          >
            <ng-container *ngIf="selectMultiple">
              <td>
                <mat-checkbox
                  [checked]="contains(item)"
                  (change)="handlerSelectRow(i)"
                ></mat-checkbox>
              </td>
            </ng-container>

            <ng-container
              *ngFor="
                let headerItem of headerData;
                index as index;
                trackBy: trackByHeader
              "
            >
              <td
                class="td"
                [ngStyle]="{
                  justifyContent: getAlignment(headerItem),
                  minWidth: headerItem.width || '250px',
                  maxWidth: headerItem.width || '250px'
                }"
              >
                <!-- STANDART TYPE -->
                <ng-container *ngIf="headerItem.variant === 'standard'">
                  <ng-container *ngIf="headerItem?.date">
                    <p [ngClass]="headerItem?.className ?? ''">
                      <ng-container *ngIf="item[headerItem.key]">
                        {{
                          (item[headerItem.key] ??
                            headerItem?.ifNotContent(item) ??
                            "-" | date : headerItem.date?.pipeTemplate) +
                            (headerItem.euros ? "€" : "")
                        }}
                      </ng-container>
                      <ng-container *ngIf="!item[headerItem.key]">
                        {{
                          headerItem?.ifNotContent(item) ??
                            "-" + (headerItem.euros ? " €" : "")
                        }}
                      </ng-container>
                    </p>
                  </ng-container>

                  <ng-container *ngIf="!headerItem?.date">
                    <p [ngClass]="headerItem?.className ?? ''">
                      {{
                        (item[headerItem.key] ??
                          headerItem?.ifNotContent(item) ??
                          "-") +
                          (headerItem.euros ? " €" : "")
                      }}
                    </p>
                  </ng-container>
                </ng-container>

                <!-- BOLD TYPE -->
                <ng-container *ngIf="headerItem.variant === 'bold'">
                  <ng-container *ngIf="headerItem?.date">
                    <p [ngClass]="headerItem?.className ?? ''">
                      <ng-container *ngIf="item[headerItem.key]">
                        <b>
                          {{
                            (item[headerItem.key] ??
                              headerItem?.ifNotContent(item) ??
                              "-" | date : headerItem.date?.pipeTemplate) +
                              (headerItem.euros
                                ? "
                          €"
                                : "")
                          }}
                        </b>
                      </ng-container>
                      <ng-container *ngIf="!item[headerItem.key]">
                        <b>
                          {{
                            headerItem?.ifNotContent(item) ??
                              "-" + (headerItem.euros ? " €" : "")
                          }}
                        </b>
                      </ng-container>
                    </p>
                  </ng-container>
                  <ng-container *ngIf="!headerItem?.date">
                    <p [ngClass]="headerItem?.className ?? ''">
                      <b>
                        {{
                          (item[headerItem.key] ??
                            headerItem?.ifNotContent(item) ??
                            "-") +
                            (headerItem.euros ? " €" : "")
                        }}
                      </b>
                    </p>
                  </ng-container>
                </ng-container>

                <!-- BOX TYPE -->
                <ng-container *ngIf="headerItem.variant === 'box'">
                  <table-item-box
                    [value]="item[headerItem.key]"
                    [type]="
                      headerItem?.classNameGenerator(
                        item[headerItem?.key ?? ''] ?? '',
                        i,
                        item
                      ) ?? ''
                    "
                  ></table-item-box>
                </ng-container>

                <!-- CHIP TYPE -->
                <ng-container *ngIf="headerItem.variant === 'chip'">
                  <table-item-chip
                    [value]="item[headerItem.key]"
                    [type]="
                      headerItem?.classNameGenerator(
                        item[headerItem?.key ?? 0],
                        i,
                        item
                      ) ?? ''
                    "
                    [className]="headerItem.className ?? ''"
                    [date]="headerItem.date ?? null"
                    [border]="headerItem.border ?? false"
                  ></table-item-chip>
                </ng-container>

                <!-- COMPONENT TYPE -->

                <ng-container *ngIf="headerItem.variant === 'component'">
                  <div
                    class="componentDiv"
                    [ngClass]="headerItem.cellClassName ?? ''"
                    appAnchor
                  ></div>
                </ng-container>

                <!-- BUTTON -->
                <ng-container *ngIf="headerItem.variant === 'button'">
                  <table-item-button
                    [icon]="headerItem.icon"
                    [buttonStyle]="
                      headerItem.buttonStyle
                        ? headerItem.buttonStyle
                        : 'principalButton'
                    "
                    [text]="headerItem.text"
                    [item]="item"
                    [index]="i"
                    [action]="headerItem.action"
                    [displayGenerator]="headerItem.displayGenerator"
                    (click)="handleClickBtn(item, headerItem.action)"
                  ></table-item-button>
                </ng-container>

                <!-- Dropdown -->
                <ng-container *ngIf="headerItem.variant === 'dropdown'">
                  <options-menu [options]="headerItem.options"></options-menu>
                </ng-container>

                <!-- ICON -->
                <ng-container *ngIf="headerItem.variant === 'icons'">
                  <div class="iconContainer" #iconContainer>
                    <ng-container *ngFor="let icon of headerItem.icons">
                      <span
                        class="iconContainer--box"
                        (click)="
                          $event.stopImmediatePropagation();
                          executeIconAction(icon.action, item)
                        "
                        [ngClass]="{
                          disabled:
                            icon.enableCondition !== undefined &&
                            icon.enableCondition(item) === false
                        }"
                      >
                        <ng-container *ngIf="icon.matIconName">
                          <mat-icon>{{ icon.matIconName }}</mat-icon>
                        </ng-container>

                        <ng-container *ngIf="icon.image">
                          <img src="{{ icon.image }}" />
                        </ng-container>
                      </span>
                    </ng-container>
                  </div>
                </ng-container>

                <!-- TICK -->
                @if(headerItem.variant === 'tick'){
                <table-tick
                  [options]="headerItem.options(item, i)"
                ></table-tick>
                }

                <!-- TOOLTIP TYPE -->
                <ng-container *ngIf="headerItem.variant === 'tooltip'">
                  <ng-container *ngIf="headerItem?.date">
                    <span
                      [ngClass]="
                        (headerItem?.className ?? '') +
                        (headerItem.tooltipItemsLength(item) > 1
                          ? 'tooltipTable'
                          : '')
                      "
                    >
                      <ng-container *ngIf="headerItem.itemToDisplay(item)">
                        <p>
                          {{
                            (headerItem.itemToDisplay(item) ?? "-"
                              | date : headerItem.date?.pipeTemplate) +
                              (headerItem.euros
                                ? "
                                        €"
                                : "")
                          }}
                        </p>
                      </ng-container>
                      <ng-container *ngIf="!headerItem.itemToDisplay(item)">
                        {{ "-" + (headerItem.euros ? " €" : "") }}
                      </ng-container>
                    </span>
                  </ng-container>
                  <ng-container *ngIf="!headerItem?.date">
                    <span
                      [ngClass]="
                        (headerItem?.className ?? '') +
                        (headerItem.tooltipItemsLength(item) > 1
                          ? ' tooltipTable'
                          : '') +
                        (i > 5 && headerItem.tooltipItemsLength(item) > 1
                          ? ' above'
                          : '')
                      "
                    >
                      <p>
                        {{
                          (headerItem.itemToDisplay(item) ?? "-") +
                            (headerItem.euros ? " €" : "")
                        }}
                      </p>

                      @if(headerItem.tooltipItemsLength(item) > 1){

                      <div class="tooltip">
                        @for (tooltipItem of headerItem.tooltipItems(item);
                        track $index) {
                        <p class="tooltip__item">
                          {{ tooltipItem }}
                        </p>
                        }
                      </div>
                      }
                    </span>
                  </ng-container>
                </ng-container>
              </td>
            </ng-container>
          </tr>
          }
        </ng-container>
      </tbody>
    </table>
  </ng-container>
</div>
