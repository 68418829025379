<div id="add-to-list-input">
  <div class="inputAndButton">
    <customInput
      [options]="customInputOptions"
      (keydown.enter)="onEnterPressed(model)"
      [(ngModel)]="model"
    ></customInput>

    <span class="addButton" (click)="onEnterPressed(model)">
      <span class="circleWithAdd">
        <img [src]="IconsHandlerService.getAddSkyBlueIcon()" alt="" />
      </span>
    </span>
  </div>

  <div class="elements-list">
    @for (lab of labs; track lab.id) {
    <div class="elements-item">
      <p>{{ lab.name }}</p>
      <mat-icon (click)="deleteLab(lab.id)">close</mat-icon>
    </div>
    }
  </div>
</div>
