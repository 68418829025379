import { Component, EventEmitter, Input, OnInit, Output, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'dark-customSearcher',
  templateUrl: './dark-customSearcher.component.html',
  styleUrls: ['./dark-customSearcher.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DarkCustomSearcherComponent), // replace name as appropriate
      multi: true,
    },
  ],

})
export class DarkCustomSearcherComponent implements OnInit {

  @Input({required: true})  placeholder : string = 'Busca aquí...';
  //INTERNAL VALUES

  private _onChange = (_: any) => {};
  private _onTouched = () => {};
  private _oldData:any;

  _disabled: boolean = false;
  model:any;

  writeValue(obj: any): void {
    if (obj !== this.model) {
      this.model = obj;
      this._onChange(obj);
    }
  }

  setDisabledState?(isDisabled: boolean): void {
    this._disabled = isDisabled;
  }

  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  onNgModelChange(event:any) {
    this._oldData = this.model;
    this.model = event;
  }

  onModelChange($event:any) {
    this.model = $event;
    this._onChange(this.model);
  }


  constructor() { }

  ngOnInit() {
  }

}
