import {
  iButton,
  IOptionCustomTextArea,
  iOptionsCustomInput,
  iOptionsSelector,
} from '@quasar-dynamics/ngx-qd-design-system';
import { iButtonOptions } from '../Interfaces/Utils/iButtonOptions';
import { IconsHandlerService } from '../../Services/Utils/IconsHandler.service';

export class CreateEditOfferClass {
  // Custom input options
  offerNumberCustomInputOptions: iOptionsCustomInput = {
    placeholder: 'XX-XXX/XXXXXX',
    type: 'text',
    label: 'Nº de oferta',
    required: true,
    classList: 'dark-input',
  };
  offerRequestCustomInputOptions: iOptionsCustomInput = {
    placeholder: 'Escriba la petición de oferta',
    type: 'text',
    label: 'Peticíon de oferta',
    required: true,
    classList: 'dark-input',
  };

  //   Selector
  clientSelectorOptions: iOptionsSelector = {
    items: [{ id: 1, name: 'Cliente 1' }],
    append: true,
    label: 'Cliente',
    classList: 'dark-selector',
    bindLabel: 'name',
    bindValue: 'id',
    search: true,
    placeholder: 'Selecciona un cliente',
    clearable: false,
  };

  //   ButtonOptions
  addClientButtonOptions: iButtonOptions = {
    text: 'Crear cliente',
    class: 'quintary',
    icon: {
      icon: true,
      image: IconsHandlerService.getAddDarkBlueIcon(),
    },
  };

  //   CustomTextArea
  offerDescriptionCustomTextAreaOptions: IOptionCustomTextArea = {
    cols: 30,
    rows: 3,
    label: 'Descripción de la oferta',
    placeholder: 'Escribe la descripción de la oferta',
    classList: 'dark-textarea',
  };
  offerObservationsCustomTextAreaOptions: IOptionCustomTextArea = {
    cols: 30,
    rows: 3,
    label: 'Añadir observaciones',
    placeholder: 'Escribe aquí una observación',
    classList: 'dark-textarea',
  };
}
