import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AddDocumentPopupClass } from '../../Shared/Class/AddDocumentPopupClass';
import { iBase64File } from '../../Shared/Interfaces/Utils/iBase64File';
import { iArchives } from '../../Shared/Interfaces/Utils/iComponentById';

@Component({
  selector: 'Add-Document-Popup',
  templateUrl: './Add-Document-Popup.component.html',
  styleUrls: ['./Add-Document-Popup.component.scss'],
})
export class AddDocumentPopupComponent implements OnInit {
  extraClass: AddDocumentPopupClass = new AddDocumentPopupClass();

  constructor(
    public dialogRef: MatDialogRef<AddDocumentPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public modalData: any
  ) {}

  ngOnInit() {
    this.checkIfEdit();
  }

  /**
   * FUNCTIONALITY
   */
  closePopup(returnValue?: any) {
    let p = { returnValue: returnValue };
    this.dialogRef.close(p);
  }

  getFiles(files: iBase64File[]) {
    this.extraClass.filesArray = files;
    this.extraClass.ngModels.base64 = files[0].base64;
  }

  checkIsComplete() {
    const dataToCheck = this.modalData?.edit
      ? [this.extraClass.ngModels.name]
      : [this.extraClass.ngModels.base64, this.extraClass.ngModels.name];
    this.extraClass.isCompleteObject = dataToCheck.every(
      (data) => data !== '' && data !== null && data !== undefined
    );
  }

  checkIfEdit() {
    if (this.modalData?.edit) {
      this.setModalData(this.modalData);
    }
  }

  setModalData(modalData: { edit: true; document: iArchives }) {
    this.extraClass.ngModels.name = modalData.document.name;
    this.extraClass.ngModels.observations = modalData.document.observations;
    this.extraClass.ngModels.annex = modalData.document.isAnnex ?? false;
  }

  onSaveButtonClick() {
    this.closePopup(this.extraClass.ngModels);
  }
}
