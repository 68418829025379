import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { SidebarComponent } from './Layout/Sidebar/Sidebar.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgxQdDesignSystemModule } from '@quasar-dynamics/ngx-qd-design-system';
import { RouterModule } from '@angular/router';
import { SiteContainerComponent } from './Layout/SiteContainer/SiteContainer.component';
import { DarkCustomSearcherComponent } from './Components/dark-customSearcher/dark-customSearcher.component';
import { FormsModule } from '@angular/forms';
import { CustomButtonComponent } from './Components/custom-button/custom-button.component';
import { MainTableComponent } from './Components/main-table/main-table.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { TableItemBoxComponent } from './Components/main-table/_holders/table-item-box/table-item-box.component';
import { TableItemButtonComponent } from './Components/main-table/_holders/table-item-button/table-item-button.component';
import { TableItemChipComponent } from './Components/main-table/_holders/table-item-chip/table-item-chip.component';
import { OptionsMenuComponent } from './Components/options-menu/options-menu.component';
import { ChipComponent } from './Components/chip/chip.component';
import { MatMenuModule } from '@angular/material/menu';
import { TableTickComponent } from './Components/table-tick/table-tick.component';
import { GoBackComponent } from './Components/go-back/go-back.component';
import { ComponentUpdateSectionComponent } from './Components/component-update-section/component-update-section.component';
import { AddAndSearchSelectorComponent } from './Components/add-and-search-selector/add-and-search-selector.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { DocumentsTableComponent } from './Components/documents-table/documents-table.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { AddFileComponent } from './Components/Add-file/Add-file.component';
import { NnLoaderComponent } from './Components/nn-loader/nn-loader.component';
import { SkeletonDirective } from './Directives/Skeleton.directive';
import { ComponentTypeTreeComponent } from './Components/component-type-tree/component-type-tree.component';
import { IconContainerComponent } from './Components/icon-container/icon-container.component';
import { SectionLineComponent } from './Components/section-line/section-line.component';
import { SubsectionLineComponent } from './Components/subsection-line/subsection-line.component';
import { CcaLineComponent } from './Components/cca-line/cca-line.component';
import { MatRadioModule } from '@angular/material/radio';
import { ColorOfferComponent } from './Layout/color-offer/color-offer.component';
import { CreateEditOfferComponent } from './Components/create-edit-offer/create-edit-offer.component';
import { AddToListInputComponent } from './Components/add-to-list-input/add-to-list-input.component';

@NgModule({
  declarations: [
    SidebarComponent,
    SiteContainerComponent,
    DarkCustomSearcherComponent,
    CustomButtonComponent,
    MainTableComponent,
    TableItemBoxComponent,
    TableItemButtonComponent,
    TableItemChipComponent,
    OptionsMenuComponent,
    ChipComponent,
    TableTickComponent,
    GoBackComponent,
    ComponentUpdateSectionComponent,
    AddAndSearchSelectorComponent,
    DocumentsTableComponent,
    AddFileComponent,
    NnLoaderComponent,
    SkeletonDirective,
    ComponentTypeTreeComponent,
    IconContainerComponent,
    SectionLineComponent,
    SubsectionLineComponent,
    CcaLineComponent,
    ColorOfferComponent,
    CreateEditOfferComponent,
    AddToListInputComponent,
  ],
  exports: [
    SidebarComponent,
    SiteContainerComponent,
    DarkCustomSearcherComponent,
    CustomButtonComponent,
    MainTableComponent,
    TableItemBoxComponent,
    TableItemButtonComponent,
    TableItemChipComponent,
    OptionsMenuComponent,
    ChipComponent,
    TableTickComponent,
    GoBackComponent,
    ComponentUpdateSectionComponent,
    AddAndSearchSelectorComponent,
    DocumentsTableComponent,
    AddFileComponent,
    NnLoaderComponent,
    SkeletonDirective,
    ComponentTypeTreeComponent,
    IconContainerComponent,
    SectionLineComponent,
    SubsectionLineComponent,
    CcaLineComponent,
    CreateEditOfferComponent,
    AddToListInputComponent,
  ],
  imports: [
    CommonModule,
    MatIconModule,
    MatTooltipModule,
    RouterModule,
    NgxQdDesignSystemModule,
    FormsModule,
    MatCheckboxModule,
    MatMenuModule,
    NgSelectModule,
    DragDropModule,
    MatRadioModule,
  ],
})
export class SharedModule {}
