<btn classList="generalButton {{options.class}}" (click)="sendOnClick()">

  @if (options.icon.icon) {
  <span class="rounded">
    @if (options.icon.image) {
    <img [src]="options.icon.image" alt="add">
    }
    @if (options.icon.matIcon) {
    <mat-icon>{{options.icon.matIcon}}</mat-icon>
    }
  </span>
  }
  {{options.text}}
</btn>