import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CcaLineClass } from '../../Class/CcaLineClass';
import {
  CCA,
  IComponentTypeByIdToEdit,
} from '../../Interfaces/Utils/iComponentTypeByIdToEdit';
import { MatRadioChange } from '@angular/material/radio';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { DefaultDependenciesInjectorService } from '../../../Services/Utils/default-dependencies-injector.service';
import { CCATypeService } from '../../../Services/Api/CCAType.service';
import {
  StaticUtilitiesService,
  iResultHttp,
} from '@quasar-dynamics/ngx-qd-design-system';
import { filter, takeUntil } from 'rxjs';
import { AssureService } from '../../../Services/Utils/Assure.service';
import { CheckIsComponentTypeCompleteService } from '../../../Services/Utils/CheckIsComponentTypeComplete.service';
import { FieldCCAService } from '../../../Services/Api/FieldCCA.service';

@Component({
  selector: 'cca-line',
  templateUrl: './cca-line.component.html',
  styleUrls: ['./cca-line.component.scss'],
})
export class CcaLineComponent implements OnInit {
  @Output() open: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() recall: EventEmitter<any> = new EventEmitter();
  @Output() moveTo: EventEmitter<CCA> = new EventEmitter<CCA>();
  @Output() onOpenCCA: EventEmitter<CCA> = new EventEmitter<CCA>();

  @Input() isInside: boolean = false;
  @Input() isComponent: boolean = false;
  @Input() isInSubsection: boolean = false;
  @Input() last: boolean = false;
  @Input()
  get cca() {
    return this.extraClass._cca;
  }
  set cca(value: CCA) {
    this.extraClass._cca = value;
    if (value.isCC) {
      this.isOpen = true;
    }
    this.checkIsCompleteHandler(value.name);
  }

  @Input() get isOpen() {
    return this.extraClass._isOpen;
  }
  set isOpen(value: boolean | undefined) {
    this.extraClass._isOpen = value;
    if (value) this.toggleInfoContainer();
  }

  extraClass: CcaLineClass = new CcaLineClass(
    this.assureDelete.bind(this),
    this.assureCopy.bind(this)
  );

  constructor(
    private DDIS: DefaultDependenciesInjectorService,
    private CCASE: CCATypeService,
    private assureSE: AssureService,
    private checkIsCompleteSE: CheckIsComponentTypeCompleteService,
    private fieldCCASE: FieldCCAService
  ) {}

  ngOnInit() {
    this.checkIfIsOfferHandler();
  }

  /**
   * HANDLER
   */
  checkIfIsOfferHandler() {
    const isOffer = this.DDIS.staticUtilitiesSE
      .getActualRoute()
      .includes('offer');
    !isOffer ? this.extraClass.iconsSection.splice(0, 1) : null;
  }

  /***
   * API CALLS HANDLER
   */
  successUpdateCCAHandler(res: iResultHttp) {
    this.DDIS.generalLoaderSE.removeFromLoaderAmount();
  }
  successDeleteCCAHandler(res: iResultHttp) {
    this.recall.emit(true);
    this.DDIS.generalLoaderSE.removeFromLoaderAmount();
  }
  successUpdateFieldCCAHandler(res: iResultHttp) {
    this.DDIS.generalLoaderSE.removeFromLoaderAmount();
  }
  successDeleteFieldCCAHandler(res: iResultHttp) {
    this.recall.emit(true);
    this.DDIS.generalLoaderSE.removeFromLoaderAmount();
  }
  successCopyFieldCCAHandler(res: iResultHttp) {
    this.recall.emit(true);
    this.DDIS.generalLoaderSE.removeFromLoaderAmount();
  }

  /**
   * FUNCTIONALITIES
   */

  toggleInfoContainer() {
    this.extraClass.showInfoContainer = !this.extraClass.showInfoContainer;
    this.onOpenCCA.emit(this.cca);
    this.open.emit(true);
  }

  onRadioButtonChange(event: MatRadioChange, key: string) {
    const { value } = event;
    this.open.emit(true);
    this.prepareUpdate(value, key);
  }

  onCheckboxChange(event: MatCheckboxChange, key: string) {
    const { checked } = event;
    this.open.emit(true);
    this.prepareUpdate(checked, key);
  }

  checkIfElementIsFull(value: any, key: string) {
    if (!value) {
      StaticUtilitiesService.showWarning('No puedes dejar este campo vacío');
      return;
    }
    this.prepareUpdate(value, key);
    this.checkIsCompleteHandler(value);
  }

  prepareUpdate(value: any, key: string) {
    const objectToPass = {
      id: this.cca.id,
      [key]: value,
    };
    if (this.isComponent) {
      this.updateFieldCCA(objectToPass);
      return;
    }
    this.updateCCA(objectToPass);
  }

  assureDelete(element: string, id: number) {
    this.assureSE.assureDelete(element, () => {
      if (this.isComponent) {
        this.deleteFieldCCA(id);
        return;
      }
      this.deleteCCA(id);
    });
  }
  assureCopy(element: string, id: number) {
    this.assureSE.assureCopy(element, () => {
      this.copyFieldCCA(id);
    });
  }

  sendOpenPopup(cca: CCA) {
    this.moveTo.emit(cca);
  }

  checkIsCompleteHandler(value: any) {
    this.checkIsCompleteSE.setElement(
      this.cca.id,
      null !== value && undefined !== value && '' !== value && false !== value
    );
  }

  /***
   * API CALLS
   */

  updateCCA(objectToPass: any) {
    this.DDIS.generalLoaderSE.addToLoaderAmount();
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.CCASE.update(behaviorSubject, objectToPass);
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () => this.successUpdateCCAHandler(res),
          },
          {
            method: () => this.DDIS.generalLoaderSE.removeFromLoaderAmount(),
            error: true,
          },
        ]);
      });
  }

  deleteCCA(id: number) {
    this.DDIS.generalLoaderSE.addToLoaderAmount();
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.CCASE.delete(behaviorSubject, id);
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () => this.successDeleteCCAHandler(res),
          },
          {
            method: () => this.DDIS.generalLoaderSE.removeFromLoaderAmount(),
            error: true,
          },
        ]);
      });
  }

  updateFieldCCA(objectToPass: any) {
    this.DDIS.generalLoaderSE.addToLoaderAmount();
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.fieldCCASE.update(behaviorSubject, objectToPass);
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () => this.successUpdateFieldCCAHandler(res),
          },
          {
            method: () => this.DDIS.generalLoaderSE.removeFromLoaderAmount(),
            error: true,
          },
        ]);
      });
  }

  deleteFieldCCA(id: number) {
    this.DDIS.generalLoaderSE.addToLoaderAmount();
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.fieldCCASE.delete(behaviorSubject, id);
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () => this.successDeleteFieldCCAHandler(res),
          },
          {
            method: () => this.DDIS.generalLoaderSE.removeFromLoaderAmount(),
            error: true,
          },
        ]);
      });
  }
  copyFieldCCA(id: number) {
    this.DDIS.generalLoaderSE.addToLoaderAmount();
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.fieldCCASE.copy(behaviorSubject, id);
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () => this.successCopyFieldCCAHandler(res),
          },
          {
            method: () => this.DDIS.generalLoaderSE.removeFromLoaderAmount(),
            error: true,
          },
        ]);
      });
  }
}
