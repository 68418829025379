<ng-container *ngIf="displayGenerator ? displayGenerator(item) : true; else noButton ">
  <btn classList="generalbutton {{buttonStyle}}" (click)="$event.stopPropagation();action(item,index)">
    <ng-container *ngIf="icon" >
      <mat-icon> {{icon}} </mat-icon>
    </ng-container>
    
    <ng-container *ngIf="text" >
      <p>{{text}}</p>
    </ng-container>
  </btn>
</ng-container>

<ng-template #noButton >
  
    <p>-</p>
  
</ng-template>